.about_main_picute {
  width: 100%;
}
.about_section_texts {
  margin-top: 50px;
  @include media-max(500px) {
    margin-top: 20px;
  }
  h1 {
    font-size: 2rem;
    font-weight: 400;
    color: $color-text;
    text-align: center;
    margin-bottom: 20px;
    @include media-max(500px) {
      font-size: 1.7em;
    }
  }
  p {
    font-size: 0.95em;
    margin-bottom: 1rem;
    @include media-max(500px) {
      font-size: 0.7em;
    }
  }
}
