.not_fount_page {
  @include flex-center;
  padding: 20px;
  .not_fount_page_content {
    @include flex-column-center;
    button {
      margin-top: 30px;
      @include media-max(550px) {
        margin: 0;
      }
    }
    svg {
      width: 500px;
      @include media-max(800px) {
        width: 400px;
      }
      @include media-max(550px) {
        width: 290px;
        height: 400px;
      }
    }
  }
}

main.content {
  .not_fount_page {
    margin: 50px 0;
    button {
      background: $color-primary;
      &:hover {
        box-shadow: 0 8px 25px -8px $color-primary;
      }
    }
  }
}
