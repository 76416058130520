.cms_content {
  .table {
    margin: 20px 0;
    .table_holder {
      .table_cell,
      a {
        @include table-text;
        @include media-max(1600px) {
          font-size: 13px;
        }
        @include media-max(1400px) {
          font-size: 11px;
        }
        .avatar {
          margin-right: 10px;
        }
      }
      .header {
        background: #e7e3e3;
        height: 50px;
        padding: 0 10px;
      }
      .content {
        .table_row {
          position: relative;
          height: 50px;
          padding: 0 10px;
          &:nth-child(even) {
            background: #f8f8f8;
          }
        }
      }
    }
  }
  .no_result {
    @include flex-center;
    font-size: 18px;
    @include media-max(500px) {
      font-size: 14px;
    }
    svg {
      font-size: 1.7em;
      margin-right: 5px;
    }
  }

  .table_helper {
    @include flex-between;
    @include media-max(750px) {
      @include flex-column-center;
      .align_row {
        width: 100%;
        &:first-child {
          order: 2;
        }
        &:last-child {
          order: 1;
          margin-bottom: 20px;
        }
      }
    }
    .paginate {
      width: 80px;
      height: 40px;
      border-radius: 6px;
      margin-right: 20px;
      @include border(1, #dae1e7);
      position: relative;
      .root {
        @include full-width;
        @include flex-between;
        padding: 0 13px;
        color: #626262;
        cursor: pointer;
        @include media-max(750px) {
          padding: 0 10px;
          font-size: 12px;
        }
        .rotate_arrow {
          @include transition;
          transform: rotate(0);
          &.active {
            transform: rotate(180deg);
          }
        }
      }
      .dropdown_content {
        position: absolute;
        top: calc(100% + 5px);
        left: 0;
        right: 0;
        background: $color-white;
        z-index: 3;
        border-radius: 6px;
        box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.1);
        border: 1px solid rgba(0, 0, 0, 0.1);
        & > div {
          width: 100%;
          text-align: center;
          cursor: pointer;
          padding: 6px 0;
          color: #626262;
          font-size: 14px;
          @include hover {
            background: $color-grey-10;
          }
        }
      }
    }
    .search_holder {
      width: 250px;
      height: 40px;
      position: relative;
      margin-left: 10px;
      @include media-max(750px) {
        margin: 0;
        width: 350px;
      }
      @include media-max(500px) {
        width: 100%;
      }
      svg {
        position: absolute;
        top: 50%;
        left: 15px;
        transform: translateY(-50%);
        color: rgba($color: $color-black, $alpha: 0.5);
      }
      input {
        width: 100%;
        height: 100%;
        font-size: 13px;
        padding: 0 20px 0 40px;
        background: transparent;
        color: $color-text;
        border-radius: 6px;
        @include border(1, #dae1e7);
        @include placeholder {
          color: rgba($color: $color-black, $alpha: 0.5);
        }
        @include transition;
        &:focus {
          box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
          border-color: $color-purple;
        }
      }
      button {
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        background: $color-white;
        width: 20px;
        height: 20px;
        @include flex-center;
        border: 1px solid #ddd;
        color: #ddd;
        border-radius: 4px;
      }
    }
  }

  .pagination_nav {
    @include flex-align-center;
    justify-content: flex-end;
    ul {
      @include flex-align-center;
      background: $color-grey-11;
      border-radius: $radius-pill;
      li {
        width: 40px;
        height: 40px;
        cursor: pointer;
        border-radius: $radius-round;
        font-weight: bold;
        font-size: $font-size-sm;
        color: rgba($color: $color-black, $alpha: 0.6);
        @include flex-center;
        &.active {
          background: $color-purple;
          color: $color-white;
        }
        @include media-max(500px) {
          width: 30px;
          height: 30px;
          font-size: 12px;
        }
      }
    }
  }
}
