.table-linkingProductsOurProducts {
  .content {
    height: 250px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 10px;
    }
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    &::-webkit-scrollbar-thumb {
      background: $color-grey-07;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: $color-grey-01;
    }
  }
  .header,
  .table_row {
    display: grid;
    align-items: center;
    grid-template-columns: 150px 1fr 3fr 90px 30px;
    @include media-max(950px) {
      grid-template-columns: 1fr 1fr 90px 30px;
    }
    @include media-max(750px) {
      grid-template-columns: 1fr 90px 30px;
    }
  }
  .header {
    @include media-max(950px) {
      padding-left: 45px !important;
      .table_cell {
        &:first-child {
          display: flex !important;
        }
        &:nth-child(2) {
          display: flex !important;
          @include media-max(750px) {
            display: none !important;
          }
        }
        &:nth-child(3) {
          display: none !important;
        }
        display: none;
      }
    }
  }
  .table_row {
    @include media-max(950px) {
      @include flex-align-center;
      flex-wrap: wrap;
      height: unset !important;
      padding: 12px 10px !important;
      .expand_button {
        display: block;
      }
      .table_cell {
        &:nth-child(2) {
          margin-left: 10px;
          display: flex !important;
          flex: 1;
          a {
            width: 100% !important;
          }
        }
        &:nth-child(3) {
          display: flex !important;
          flex: 1;
          @include media-max(750px) {
            display: none !important;
          }
        }
        &:nth-child(4) {
          display: none !important;
        }
        display: none;
      }
      .product_table_icons {
        width: 90px !important;
        display: flex !important;
      }
    }
  }
  .mobile_table_wrapper {
    @include media-max(950px) {
      display: block;
      .mobile_table_holder {
        .mobile_table_row {
          .row_label {
            min-width: 70px;
          }
          .linking_products_mobile {
            .row {
              display: grid;
              grid-template-columns: repeat(2, 1fr);
              &.top {
                font-weight: bold;
                background: rgba($color: $color-black, $alpha: 0.05);
              }
              & > div {
                padding: 5px;
                font-size: 12px;
                @include media-max(600px) {
                  font-size: 10px;
                }
              }
            }
          }
        }
      }
      .mobile_table_row:nth-child(1) {
        display: none;
        @include media-max(750px) {
          display: grid;
        }
      }
    }
  }
  .product_table_icons {
    @include media-max(950px) {
      display: none;
    }
  }
  .linking_product_modal_wrapper {
    @include media-max(1200px) {
      width: 600px !important;
    }
    @include media-max(750px) {
      width: 400px !important;
    }
    @include media-max(550px) {
      width: 250px !important;
    }
  }
}

.linking_products_modal {
  .MuiPaper-root {
    .MuiDialogTitle-root {
      padding: 0 0 20px 0;
      h2 {
        text-align: center;
      }
    }
    padding: 30px;
    .MuiDialogContent-root {
      padding: 20px 0 0 0;
    }
    .buttons_holder {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 0 20px;
      margin-top: 30px;
    }
  }
}

.table-groupedProducts {
  margin-bottom: 60px;
  .header,
  .table_row {
    display: grid;
    align-items: center;
    grid-template-columns: 150px 1fr 3fr 100px 60px;
    @include media-max(1200px) {
      grid-template-columns: 120px 1fr 1fr 70px 60px;
    }
    @include media-max(950px) {
      grid-template-columns: 150px 200px;
    }
    .table_cell {
      &:last-child {
        overflow: unset !important;
      }
    }
  }
  .header {
    @include media-max(950px) {
      padding-left: 45px !important;
      .table_cell {
        &:first-child {
          display: flex !important;
        }
        &:nth-child(2) {
          display: flex !important;
          @include media-max(650px) {
            display: none !important;
          }
        }
        display: none;
      }
    }
  }
  .table_row {
    .table_cell:nth-child(6) {
      overflow: unset !important;
    }
    @include media-max(950px) {
      @include flex-align-center;
      flex-wrap: wrap;
      height: unset !important;
      padding: 12px 10px !important;
      .expand_button {
        display: block;
      }
      .table_cell {
        &:nth-child(2) {
          margin-left: 10px;
          display: flex !important;
          width: 150px !important;
          a {
            width: 100% !important;
          }
        }
        &:nth-child(3) {
          display: flex !important;
          width: 200px !important;
          @include media-max(650px) {
            display: none !important;
          }
        }
        &:nth-child(6) {
          position: absolute;
          right: 10px;
          top: 10px;
          display: flex !important;
          width: 35px !important;
          height: 30px !important;
          .linking_modal_wrap_button {
            display: none !important;
          }
        }
        display: none;
      }
    }
  }
  .mobile_table_wrapper {
    @include media-max(950px) {
      display: block;
      .mobile_table_holder {
        .mobile_table_row {
          .row_label {
            min-width: 70px;
          }
          .linking_products_mobile {
            .row {
              display: grid;
              grid-template-columns: repeat(2, 1fr);
              &.top {
                font-weight: bold;
                background: rgba($color: $color-black, $alpha: 0.05);
              }
              & > div {
                padding: 5px;
                font-size: 12px;
                @include media-max(600px) {
                  font-size: 10px;
                }
              }
            }
          }
        }
      }
      .mobile_table_row:nth-child(1) {
        display: none;
        @include media-max(650px) {
          display: grid;
        }
      }
    }
  }
  .content {
    height: 250px;
    overflow-y: scroll;
    position: relative;
    &::-webkit-scrollbar {
      width: 10px;
    }
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    &::-webkit-scrollbar-thumb {
      background: $color-grey-07;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: $color-grey-01;
    }
    .no_result {
      @include absolute-center;
      font-size: 18px;
      font-style: italic;
      color: $color-text;
      @include media-max(500px) {
        font-size: 14px;
      }
    }
  }
  .pagination_nav {
    position: absolute;
    bottom: 20px;
    right: 20px;
  }
  .linking_product_modal_wrapper {
    @include media-max(1200px) {
      width: 600px !important;
    }
    @include media-max(750px) {
      width: 400px !important;
    }
    @include media-max(550px) {
      width: 250px !important;
    }
  }
}

.multi_columns {
  margin-top: 20px;
  position: relative;
  .table_helper {
    p {
      text-transform: capitalize;
      font-size: 18px;
      color: $color-text;
      font-weight: 300;
      @include media-max(750px) {
        margin-bottom: 15px;
      }
    }
  }
}

.linking_product_filters {
  display: grid;
  align-items: center;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 0 20px;
  margin-bottom: 20px;
  @include media-max(1100px) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
  }
  @include media-max(650px) {
    grid-template-columns: 100%;
    grid-gap: 20px 0;
  }
  .search_select_wrapper {
    width: 100%;
    font-size: 13px;
    label {
      display: none !important;
    }
  }
  .search_holder {
    width: 100%;
    height: 40px;
    position: relative;
    svg {
      position: absolute;
      top: 50%;
      left: 15px;
      transform: translateY(-50%);
      color: rgba(0, 0, 0, 0.5);
    }
    input {
      width: 100%;
      height: 40px;
      @include transition;
      border: 1px solid rgba(0, 0, 0, 0.2);
      font-size: 13px;
      padding: 0 20px 0 40px;
      background: transparent;
      color: $color-text;
      border-radius: 6px;
      &::placeholder {
        color: rgba(0, 0, 0, 0.4);
      }
    }
  }
}

.linking_product_create_product_modal_inner {
  @include fixed-full;
  @include flex-center;
  background: rgba(0, 0, 0, 0.8);
  z-index: 9999;
  padding: 30px 10%;
  @include media-max(1400px) {
    padding: 30px 5%;
  }
  @include media-max(700px) {
    padding: 15px;
  }
  .close_icon {
    position: absolute;
    top: 30px;
    right: 50px;
    width: 40px;
    height: 40px;
    color: $color-danger;
    cursor: pointer;
    z-index: 2;
    @include media-max(1400px) {
      top: 10px;
      right: 20px;
      width: 30px;
      height: 30px;
    }
    @include media-max(1000px) {
      display: none;
    }
  }
  .linking_product_create_product_modal_outer {
    @include full-size;
    background: $color-white;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    position: relative;
    border-radius: 10px;
    .form_holder {
      @include full-size;
      padding: 50px 20px;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        width: 0;
      }
      @include media-max(700px) {
        padding: 30px 20px;
      }
      .form_group {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 30px;
        @include media-max(1000px) {
          grid-template-columns: 100%;
          grid-gap: 40px 0;
        }
      }
      .upload_photos_row {
        margin-top: 20px;
        display: grid;
        grid-template-columns: 25% calc(75% - 20px);
        grid-gap: 0 20px;
        @include media-max(1000px) {
          grid-template-columns: 100%;
          grid-gap: 20px 0;
        }
        .dropzone_wrapper {
          width: 100%;
          &.main_photo {
            .photo {
              border-radius: 4px;
              @include inline-bg;
              position: relative;
              height: 250px;
              @include media-max(1600px) {
                height: 200px;
              }
              svg {
                position: absolute;
                top: 5px;
                right: 5px;
                color: $color-danger;
                font-size: $size-icon-sm;
                cursor: pointer;
                font-size: 30px;
                @include media-max(1600px) {
                  font-size: 24px;
                }
              }
            }
          }
          .input_file_holder {
            height: 100px;
            border: 1px dashed rgba($color: $color-black, $alpha: 0.4);
            position: relative;
            cursor: pointer;
            margin: 20px 0;
            input {
              @include absolute-full;
              opacity: 0;
              z-index: 1;
              cursor: pointer;
            }
            span {
              @include absolute-full;
              @include flex-center;
              font-size: 18px;
              cursor: pointer;
              color: rgba($color: $color-black, $alpha: 0.4);
            }
          }
          .photos_grid {
            width: 100%;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 20px;
            @include media-max(500px) {
              grid-template-columns: 100%;
            }
            .photo {
              border-radius: 4px;
              @include inline-bg;
              height: 250px;
              position: relative;
              @include media-max(1600px) {
                height: 200px;
              }
              @include media-max(500px) {
                height: 170px;
              }
              svg {
                position: absolute;
                top: 5px;
                right: 5px;
                color: $color-danger;
                font-size: $size-icon-sm;
                cursor: pointer;
                font-size: 30px;
                @include media-max(1600px) {
                  font-size: 24px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.list_of_selected_items_outer {
  margin-bottom: 20px;
  .list_of_selected_items_inner {
    .our_product {
      margin-bottom: 20px;
    }
    .item {
      background: $color-grey-10;
      border-bottom: 1px solid #fff;
      width: 100%;
      display: grid;
      align-items: center;
      grid-template-columns: 200px 1fr auto;
      font-size: 13px;
      padding: 15px;
      @include media-max(700px) {
        grid-template-columns: 120px 1fr auto;
        font-size: 10px;
        padding: 10px 5px;
      }
      div {
        @include flex-align-center;
        a {
          width: 24px;
          height: 24px;
        }
      }
      svg {
        &.create {
          color: $color-success;
        }
        &.edit {
          color: $color-purple;
        }
        color: $color-danger;
        font-size: 24px;
        cursor: pointer;
        @include media-max(600px) {
          font-size: 18px;
        }
      }
    }
    .buttons {
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
}
