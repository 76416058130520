.jobs_page {
  img {
    width: 100%;
  }
  .grid {
    display: grid;
    grid-template-columns: 450px 1fr;
    grid-gap: 0 50px;
    margin: 50px 0;
    align-items: center;
    @include media-max(992px) {
      grid-template-columns: 300px 1fr;
    }
    @include media-max(768px) {
      grid-template-columns: 100%;
      grid-gap: 30px 0;
    }
    div {
      h1 {
        font-size: 2rem;
        margin-bottom: 20px;
        font-weight: 500;
      }
      p {
        font-size: 1rem;
        line-height: 1.6;
        color: rgba(0, 0, 0, 0.7);
        @include media-max(768px) {
          font-size: 13px;
        }
      }
      ul {
        margin-left: 20px;
        li {
          list-style-type: disc;
          color: rgba(0, 0, 0, 0.7);
          font-size: 1rem;
          line-height: 1.6;
          @include media-max(768px) {
            font-size: 13px;
          }
        }
      }
    }
    &.reverse {
      grid-template-columns: 1fr 450px;
      @include media-max(992px) {
        grid-template-columns: 1fr 300px;
      }
      @include media-max(768px) {
        grid-template-columns: 100%;
      }
    }
  }
  .row {
    @include flex-center;
    margin: 50px 0;
    @include media-max(768px) {
      @include flex-column-center;
    }
    img {
      width: 500px;
      margin-right: 50px;
      @include media-max(992px) {
        width: 300px;
      }
      @include media-max(768px) {
        margin: 0;
        width: 250px;
      }
    }
    div {
      h2 {
        font-weight: 500;
        font-size: 2rem;
        @include media-max(992px) {
          font-size: 1.5rem;
        }
        @include media-max(768px) {
          text-align: center;
        }
      }
      h3 {
        font-size: 2rem;
        margin: 10px 0 20px 0;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.4);
        @include media-max(992px) {
          font-size: 1.5rem;
        }
        @include media-max(768px) {
          text-align: center;
        }
      }
      .custom_button {
        width: 130px;
        @include hover {
          background: $color-primary;
          opacity: 0.7;
        }
        @include media-max(768px) {
          margin: 0 auto;
        }
      }
    }
  }
  .team_slider {
    h1 {
      margin-bottom: 20px;
      font-weight: 500;
      font-size: 2rem;
    }
  }
  .we_offer_section {
    background: #fafafa;
    margin-top: 50px;
    .we_offer_holder {
      padding: 20px 0;
      h2 {
        text-align: center;
        margin-bottom: 20px;
        font-weight: 500;
        font-size: 1.5rem;
      }
      .cards {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-gap: 0 20px;
        @include media-max(768px) {
          grid-template-columns: repeat(2, 1fr);
        }
        @include media-max(500px) {
          grid-template-columns: 100%;
        }
        .card {
          display: grid;
          grid-template-rows: 1fr 50px;
          align-items: flex-start;
          justify-items: center;
          grid-gap: 20px 0;
          img {
            width: 100px;
            @include media-max(992px) {
              width: 70px;
            }
          }
          p {
            text-align: center;
            font-size: 1rem;
            color: rgba(0, 0, 0, 0.5);
            @include media-max(992px) {
              font-size: 13px;
            }
          }
        }
      }
    }
  }
  .align {
    margin: 50px 0;
    text-align: center;
    p {
      font-size: 1.2em;
      margin-bottom: 1rem;
      @include media-max(500px) {
        font-size: 13px;
      }
    }
    a {
      font-size: 1.2em;
      @include media-max(500px) {
        font-size: 13px;
      }
    }
  }
}
