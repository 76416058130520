#app_bar {
  .banner_wrapper {
    background: $color-primary;
    .banner {
      display: flex;
      align-items: center;
      color: white;
      gap: 10px;
      padding: 10px 0;

      a {
        padding-right: 10px;
        font-size: 12px;
        color: white;
        &.border {
          border-right: 1px solid white;
        }
      }
    }
  }
  .top_bar_wrapper {
    border-top: 1px solid $color-grey-01;
    border-bottom: 1px solid $color-grey-01;
    margin-bottom: 35px;
    @include media-max(1200px) {
      display: none;
    }
    .container {
      .top_bar_content {
        @include flex-between;
        padding: 7px 0;
        ul.left_side {
          @include flex-align-center;
          li {
            &:nth-child(2) {
              padding: 0 20px;
              margin: 0 20px;
              border-left: 1px solid $color-grey;
              border-right: 1px solid $color-grey;
            }
            a {
              width: 22px;
              height: 22px;
              @include flex-center;
              @include border(1, $color-grey-02);
              border-radius: $radius-round;
              color: $color-grey-02;
              @include hover {
                border-color: $color-grey-03;
                color: $color-grey-03;
              }
              svg {
                font-size: $size-icon-sm;
              }
            }
          }
        }
        ul.right_side {
          @include flex-align-center;
          li {
            padding: 0 15px;
            position: relative;
            &::after {
              content: '';
              position: absolute;
              right: 0;
              width: 1px;
              top: 3px;
              bottom: 3px;
              background: $color-grey;
            }
            &:last-child {
              padding-right: 0;
              &::after {
                display: none;
              }
            }
            a {
              color: $color-grey-03;
              text-transform: uppercase;
              font-size: 13px;
              @include hover {
                color: $color-black;
              }
            }
          }
        }
      }
    }
  }
}
