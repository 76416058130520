.home_categories_section_inner {
  margin: 40px 0;
  .photo_wrap {
    width: 100%;
    @include flex-center;
    @include media-max(500px) {
      //height: 60px;
    }
  }
  img {
    width: 100%;
    max-width: 300px;
    max-height: 300px;
    @include media-max(500px) {
      // max-width: 60px;
      // max-height: 60px;
    }
  }
  h2 {
    font-size: 20px;
    font-weight: 600;
    @include media-max(500px) {
      font-size: 16px;
    }
  }
  .container {
    .home_categories_section_outer {
      //border-top: 1px solid rgba(0, 0, 0, 0.1);
      margin-top: 50px;

      display: grid;
      grid-template-columns: repeat(5, 1fr) !important;
      //grid-auto-rows: 250px;
      justify-content: space-between;
      grid-gap: 30px 20px;
      .category_item {
        height: 90% !important;
        width: 100%;
        position: relative;
        h3 {
          position: absolute;
          top: 10px;
          width: fit-content;
          left: 10px;
        }
      }
      @include media-max(1600px) {
        //grid-template-columns: repeat(5, fr) !important;
        .category_item {
          height: 70% !important;
          width: 100%;
        }
      }
      @include media-max(1300px) {
        //grid-template-columns: repeat(5, 1fr) !important;
        //grid-auto-rows: 150px !important;
        .home_categories_section_outer {
          // grid-gap: 10px 5px;
          padding-bottom: 0;
        }
        .category_item {
          height: 90% !important;
          width: 100%;
          h3 {
            margin: 0;
          }
        }
      }
      @include media-max(1000px) {
        //grid-template-columns: repeat(3, 1fr) !important;
        //grid-gap: 60px 20px;
        .category_item {
          height: 120% !important;
          width: 90% !important;
        }
      }
      @include media-max(800px) {
        display: flex;
        overflow: auto;
        // grid-template-columns: repeat(3, 180px);
        // grid-auto-rows: 180px;
      }
      @include media-max(500px) {
        // grid-template-columns: repeat(2, 140px);
        // grid-auto-rows: 140px;
        display: flex;
        overflow: auto;
      }
      .category_item {
        @include full-size;
        // @include flex-column;
        // align-items: center;
        // justify-content: center;
        cursor: pointer;
        //border: 1px solid black;
        //background: #f3f3f3;
        // transform: scale(1);
        width: 100%;
        height: 100%;
        // @include hover {
        //   transform: scale(1.1);
        // }
        // .photo_wrap {
        //   width: 100%;
        //   @include flex-center;
        //   @include media-max(500px) {
        //     //height: 60px;
        //   }
        // }
        // img {
        //   width: 100%;
        //   max-width: 300px;
        //   max-height: 300px;
        //   @include media-max(500px) {
        //     // max-width: 60px;
        //     // max-height: 60px;
        //   }
        // }
        h3 {
          text-align: center;
          color: $color-text;
          text-transform: uppercase;
          margin-top: 5px;
          font-size: 11px;
          font-weight: bold;
          height: 30px;
          width: fit-content;
          overflow: hidden;
          @include media-max(700px) {
            height: 30px;
          }
          @include media-max(500px) {
            font-size: 10px;
            height: 27px;
          }
        }
        @include media-max(700px) {
          height: 115px;
          max-width: 115px;
          min-width: 115px;
        }
        @include media-max(500px) {
          height: 115px;
          max-width: 115px;
          min-width: 115px;
        }
      }
    }
  }
}
