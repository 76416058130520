.cms_seo {
  padding: 50px;
  width: 100%;
  height: fit-content;
  display: grid;
  grid-template-columns: 200px 1fr;
  .right_side {
    padding: 0 50px;
    .area_title {
      width: 100%;
      position: relative;
      display: grid;
      grid-template-columns: 1fr 200px;
      .variables {
        padding: 0 20px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .highlight {
          color: $color-primary;
          font-weight: bold;
        }
        .variables_select {
          display: flex;
          flex-wrap: wrap;
          gap: 10px;
          margin: auto;
          .variable {
            width: fit-content;
            background-color: $color-primary;
            color: white;
            border-radius: 10vw;
            padding: 5px 20px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
