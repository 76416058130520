.simple_react_calendar {
  background: $color-white;
  width: 280px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
  z-index: 5;
  position: absolute;
  left: 0;
  top: calc(100% + 10px);
  .calendar {
    @include full-width;
    .calendar-month_header {
      width: 100%;
      padding: 10px 20px;
      @include flex-between;
      background: $color-grey-10;
      button {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        @include flex-center;
        background: transparent;
      }
    }
    .calendar-month {
      .calendar-days_of_week {
        display: grid;
        grid-template-columns: repeat(7, 40px);
        grid-template-rows: 40px;
        align-items: center;
        justify-content: center;
        justify-items: center;
        font-size: 13px;
      }
      .calendar-week {
        display: grid;
        grid-template-columns: repeat(7, 40px);
        grid-template-rows: 40px;
        button.calendar-week-day {
          background: $color-white;
          border-radius: 50%;
          &.is-today {
            background: $color-grey-01;
            color: $color-white;
          }
          &.is-selected {
            background: $color-purple;
            color: $color-white;
          }
        }
      }
    }
  }
}
