.terms_and_prices_of_delivery_page {
  h1 {
    font-size: 2rem;
    font-weight: 400;
    color: #333e48;
    text-align: center;
    @include media-max(768px) {
      font-size: 1.5em;
    }
  }
  .box_row {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 500px;
    @include media-max(1200px) {
      grid-template-columns: 1fr 400px;
    }
    @include media-max(992px) {
      grid-template-columns: 1fr 300px;
    }
    @include media-max(768px) {
      grid-template-columns: 100%;
    }
    &.reverse {
      grid-template-columns: 500px 1fr;
      @include media-max(1200px) {
        grid-template-columns: 400px 1fr;
      }
      @include media-max(992px) {
        grid-template-columns: 300px 1fr;
      }
      @include media-max(768px) {
        grid-template-columns: 100%;
      }
    }
    grid-gap: 0 50px;
    margin-top: 50px;
    align-items: center;
    .wallpaper {
      width: 100%;
      img {
        width: 100%;
      }
    }
    .details {
      h3 {
        color: $color-primary;
        font-size: 1.7em;
        font-weight: 400;
        margin-bottom: 20px;
        @include media-max(992px) {
          font-size: 1.4em;
        }
        @include media-max(500px) {
          font-size: 1.2em;
        }
      }
      h4 {
        color: $color-primary;
        font-size: 1.1em;
        font-weight: 400;
        margin-bottom: 20px;
        @include media-max(992px) {
          font-size: 1em;
        }
      }
      p {
        font-size: 1em;
        margin-bottom: 1rem;
        @include media-max(992px) {
          font-size: 0.9em;
        }
      }
    }
  }
  .icons_section {
    @include flex-column-center;
    h5 {
      margin: 30px 0;
      font-size: 1.1em;
      font-weight: 400;
      @include media-max(500px) {
        font-size: 0.8em;
      }
    }
    h6 {
      color: #9fc95a;
      margin-bottom: 10px;
      font-size: 0.8em;
      width: 50%;
      @include media-max(1200px) {
        width: 450px;
      }
      @include media-max(768px) {
        width: 90%;
      }
      @include media-max(500px) {
        width: 100%;
      }
    }
    img {
      margin-bottom: 20px;
      width: 50%;
      @include media-max(1200px) {
        width: 450px;
      }
      @include media-max(768px) {
        width: 90%;
      }
      @include media-max(500px) {
        width: 100%;
      }
      &:last-child {
        margin-top: 50px;
      }
    }
  }
  ul {
    margin: 20px 0 0 20px;
    li {
      list-style-type: disc;
      // font-weight: bold;
      margin-bottom: 0.5rem;
    }
  }
}
