.green_tooltip_wrap {
  position: absolute;
  top: calc(100% + 15px);
  left: 50%;
  z-index: 2;
  background: $color-white;
  transform: translateX(-50%);
  min-width: 200px;
  cursor: auto;
  display: none;
  @include media-max(500px) {
    min-width: 130px;
  }
  .green_tooltip_holder {
    background: $color-white;
    @include full-size;
    position: relative;
    @include border(1, rgba(0, 0, 0, 0.2));
    font-size: 12px;
    font-weight: 400;
    font-style: normal;
    color: rgba(0, 0, 0, 0.7);
    padding: 3px;
    z-index: 3;
    @include media-max(500px) {
      font-size: 10px;
    }
    &::after {
      content: '';
      position: absolute;
      top: -7.5px;
      left: 50%;
      transform: translateX(-50%) rotateZ(45deg);
      width: 15px;
      height: 15px;
      background: $color-white;
      border-top: 1px solid rgba(0, 0, 0, 0.2);
      border-left: 1px solid rgba(0, 0, 0, 0.2);
      z-index: -1;
    }
    .title {
      padding: 10px;
      background: #9fc95a;
      color: $color-white;
      font-weight: 600;
      z-index: 3;
    }
    .content {
      padding: 10px;
    }
  }
}
