.mobile_navbar_mask {
  @include fixed-full;
  background: rgba(0, 0, 0, 0.5);
  display: none;
  &.active {
    display: block;
    z-index: 999;
  }
}

.mobile_navbar_wrapper {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 250px;
  background: #3e3e3e;
  z-index: 9999999;
  overflow-y: scroll;
  margin-left: -250px;
  transition: all 0.3s ease-in-out;
  &::-webkit-scrollbar {
    width: 0;
  }
  &.active {
    margin: 0;
    // width: 100%;
  }
  .mobile_navbar_holder {
    @include full-size;
    position: relative;
    .items {
      background-color: #0787ea;
      .item_holder {
        div {
          width: 100%;
          min-height: 45px;
          display: grid;
          align-items: center;
          padding-left: 10px;
          grid-template-columns: 1fr 45px;
          grid-gap: 0 10px;
          border-bottom: 1px solid white;
          color: $color-white;
          cursor: pointer;
          font-size: 12px;
          overflow: hidden;
          line-height: 1.6;
          span {
            transition: all 1s;
            @include full-size;
            @include flex-center;
            svg {
              border: 1px solid white;
              border-radius: 3px;
              padding: 0.5px;
              width: 30px;
              height: 25px;
              font-size: 18px;
              color: rgba(255, 255, 255, 0.7);
              justify-self: center;
            }
          }
        }
        &.level_2 {
          background-color: #5fa3ff;
          width: 100%;
          min-height: 45px;
          display: grid;
          align-items: center;
          grid-gap: 0 10px;
          color: $color-white;
          cursor: pointer;
          font-size: 12px;
          overflow: hidden;
          line-height: 1.6;
          span {
            @include full-size;
            @include flex-center;
            svg {
              border: 1px solid white;
              border-radius: 3px;
              padding: 0.5px;
              width: 30px;
              height: 25px;
              font-size: 18px;
              color: rgba(255, 255, 255, 0.7);
              justify-self: center;
            }
          }

          p {
            padding-left: 20px;
          }
        }
        &.level_3 {
          background-color: #88b9ff;
          width: 100%;
          min-height: 45px;
          display: grid;
          align-items: center;
          padding-left: 10px;
          grid-template-columns: 1fr 45px;
          grid-gap: 0 10px;
          border-bottom: 1px solid white;
          color: $color-white;
          cursor: pointer;
          font-size: 12px;
          overflow: hidden;
          line-height: 1.6;

          p {
            padding-left: 30px;
          }
        }
      }
    }
  }
}
