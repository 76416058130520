.cms_page {
  &.cms_news_inner {
    .table_row {
      p {
        @include table-text;
        width: 100%;
      }
    }
    .header,
    .table_row {
      display: grid;
      align-items: center;
      grid-template-columns: 300px 1fr 70px;
      @include media-max(1000px) {
        grid-template-columns: 200px 1fr 70px;
      }
      @include media-max(600px) {
        grid-template-columns: 100px 1fr 70px;
      }
    }
    .skeleton_inner {
      .header {
        grid-template-columns: 100%;
      }
      .skeleton_content {
        grid-template-columns: 300px 1fr 30px 30px;
        @include media-max(1000px) {
          grid-template-columns: 200px 1fr 30px 30px;
        }
        @include media-max(600px) {
          grid-template-columns: 100px 1fr 30px 30px;
        }
      }
    }
  }
}

.add_cms_news_inner {
  .form_holder {
    padding-top: 20px;
  }
  .input_file_holder {
    height: 200px !important;
    margin: 40px 0 !important;
    .photo {
      @include inline-bg;
    }
  }
  .area_field {
    height: 170px;
  }
}
