.home_hero_wrap {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 20px;
  align-items: center;
  margin-top: 25px;
  @include media-max(1000px) {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }
  .latest_action {
    img {
      width: 100%;
      border-radius: 10px;
      overflow: hidden;
    }
  }
}
.home_carousel_wrap {
  width: 100%;
  height: 100%;
  position: relative;

  @include media-max(1200px) {
    margin-top: 0;
  }
  @include media-max(1000px) {
    display: flex;
    order: -1;
  }
  .carousel_arrow_left {
    display: none;
    @include media-max(1000px) {
      background: #80808069;
      position: absolute;
      height: 98%;
      color: white;
      text-align: center;
      align-items: center;
      z-index: 9999;
      display: flex;
      opacity: 0.3;
      width: auto;
      font-size: 50px;
    }
  }
  .carousel_arrow_right {
    display: none;
    @include media-max(1000px) {
      background: #80808069;
      position: absolute;
      height: 98%;
      color: white;
      text-align: center;
      align-items: center;
      z-index: 9999;
      display: flex;
      opacity: 0.3;
      right: 0;
      width: auto;
      font-size: 50px;
    }
  }
  .home_carousel_holder {
    @include full-size;
    .carousel_slide {
      display: none;
      @include full-size;
      animation: opacity 0.3s linear forwards;
      cursor: pointer;
      &.active {
        display: block;
      }
      .photo {
        height: 100%;
      }
      img {
        @include full-size;
        border-radius: 10px;
        overflow: hidden;
      }
    }
    .bullets {
      position: absolute;
      bottom: -25px;
      left: 50%;
      transform: translateX(-50%);
      @include flex-center;
      .bullet {
        display: block;
        margin: 0 3px;
        border-radius: $radius-round;
        background: rgba(230, 230, 230, 0.5);
        width: 14px;
        height: 14px;
        //border: 2px solid #007aff;
        cursor: pointer;
        @include transition;
        position: relative;
        &:hover {
          .current_carousel_info {
            display: flex;
            @include media-max(700px) {
              display: none;
            }
          }
        }
        &.active {
          background: #007aff;
        }
        .current_carousel_info {
          position: absolute;
          @include inline-bg;
          width: 135px;
          height: 60px;
          transform: translateX(-50%);
          left: 50%;
          bottom: 20px;
          @include transition;
          border-radius: 4px;
          display: none;
          overflow: hidden;
          .mask {
            @include full-size;
            @include flex-center;
            background: rgba(0, 0, 0, 0.5);
            padding: 10px;
            h4 {
              color: $color-white;
              font-size: 14px;
              z-index: 1;
              font-weight: 500;
            }
          }
        }
      }
    }
  }
}
