.promo_code_container {
  display: flex;
  flex-direction: column;
  gap: 50px;
  .code_title {
    color: gray;
  }

  .code_form {
    color: gray;
    border-radius: 7px;
    padding: 20px 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    margin-right: 180px;
  }
}
.promocodes_screen {
  .table-promocodes {
    .promoBTN {
      height: 70%;
    }
    .header {
      gap: 100px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .table_row {
      @include flex-between;
      & > .table_cell:last-child {
        margin-left: 9%;
        width: 40%;
        text-align: left;
        @include flex-align-center;
        justify-content: center;
      }
    }
  }
  @include media-max(500px) {
    .table_helper {
      .align_row {
        .table_inline_form {
          width: calc(100% - 100px);
          .input_holder {
            width: calc(100% - 650px);
          }
          button {
            margin-left: 10px;
          }
        }
      }
    }
  }
}
.promocodesModal_screen {
  .table-promocodesModal {
    .header,
    .table_row {
      @include flex-between;
      & > .table_cell:last-child {
        margin-left: 9%;
        width: 40%;
        text-align: left;
        @include flex-align-center;
        justify-content: center;
      }
    }
  }
  @include media-max(500px) {
    .table_helper {
      .align_row {
        .table_inline_form {
          width: calc(100% - 100px);
          .input_holder {
            width: calc(100% - 65px);
          }
          button {
            margin-left: 10px;
          }
        }
      }
    }
  }
}
