.wish_list_page {
  margin: 50px 0;
  .list {
    .item {
      padding: 20px 10px;
      @include flex-align-center;
      border-top: 1px solid #ddd;
      img {
        width: 100px;
        margin: 0 20px;
        @include border(1, #ddd);
        @include media-max(500px) {
          width: 70px;
          margin: 0 10px;
        }
      }
      a {
        color: $color-text;
        font-size: 14px;
        cursor: pointer;
        @include media-max(500px) {
          font-size: 12px;
        }
      }
    }
  }
}
