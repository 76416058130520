// @keyframes

@keyframes search_slidedown {
  from {
    transform: translateY(-100px);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes slidedown {
  from {
    transform: translateY(-30px);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes sticky_slow_down {
  from {
    opacity: 0;
    transform: translateY(-100%);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes opacity {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes accordian {
  from {
    height: 0;
    padding: 0 20px;
    margin: 0;
  }
  to {
    height: 190px;
    padding: 20px;
    margin-top: 20px;
  }
}

@keyframes accordian_disable {
  from {
    height: 190px;
    padding: 20px;
    margin-top: 20px;
  }
  to {
    height: 0;
    padding: 0 20px;
    margin: 0;
  }
}

@keyframes accordian2 {
  from {
    height: 0;
    padding: 0 20px;
    margin: 0;
  }
  to {
    height: 270px;
    padding: 20px;
    margin-top: 20px;
  }
}

@keyframes accordian_disable2 {
  from {
    height: 270px;
    padding: 20px;
    margin-top: 20px;
  }
  to {
    height: 0;
    padding: 0 20px;
    margin: 0;
  }
}

@keyframes sidebar_dropdown {
  from {
    height: 0;
    overflow: hidden;
  }
  to {
    height: 210px;
    overflow: visible;
  }
}

@keyframes sidebar_dropdown_close {
  from {
    height: 210px;
  }
  to {
    height: 0;
  }
}
