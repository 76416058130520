.slider_news_wrapper {
  width: 100%;
  margin: 50px 0;
  .swiper-pagination-fraction,
  .swiper-pagination-custom,
  .swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: 0;
  }

  br {
    display: none;
  }
  .container {
    h2 {
      padding-bottom: 10px;
      margin-bottom: 30px;
      position: relative;
      font-weight: 600;
      font-size: 20px;
      @include media-max(1200px) {
        font-size: 16px;
      }
    }
    .slider_news_slide {
      cursor: grab;
      // display: grid;
      // grid-template-columns: 300px 1fr;
      // grid-gap: 0 20px;
      // margin-bottom: 50px;
      display: flex;
      flex-direction: column;
      @include media-max(1480px) {
        grid-template-columns: 250px 1fr;
      }
      @include media-max(1200px) {
        grid-template-columns: 100%;
      }
      .photo {
        @include inline-bg;
        height: 400px;
        border-radius: 10px;
        overflow: hidden;
        @include media-max(1480px) {
          height: 250px;
        }
        @include media-max(1200px) {
          height: 200px;
        }
      }
      .details {
        min-height: 240px;
        position: relative;
        @include media-max(900px) {
          min-height: 260px;
        }
        h3 {
          font-size: 18px;
          font-weight: 600;
          margin: 20px 0;
          @include media-max(1480px) {
            font-size: 1.2rem;
            margin-bottom: 15px;
          }
          @include media-max(1200px) {
            margin: 10px 0;
          }
        }
        p {
          font-size: 16px;
          margin-bottom: 0.5rem;
          @include media-max(1480px) {
            font-size: 12px;
          }
          @include media-max(1200px) {
            margin-bottom: 10px;
          }
        }
        button {
          width: 200px;
          height: 45px;
          border-radius: $radius-pill;
          color: $color-white;
          background: $color-primary;
          font-size: 14px;
          position: absolute;
          bottom: 10px;
          @include flex-center;
          @include hover {
            background: $color-black;
          }
          @include media-max(1480px) {
            width: 170px;
            height: 40px;
            font-size: 12px;
          }
          @include media-max(1200px) {
            width: 150px;
            height: 35px;
          }
        }
      }
    }
  }
}
