.help_page {
  .box {
    @include border(1, #f2f2f2);
    padding: 30px;
    margin: 50px 0;
    @include media-max(500px) {
      margin: 20px 0 50px 0;
      padding: 15px;
    }
    h3 {
      text-transform: uppercase;
      font-size: 1.7em;
      color: $color-primary;
      font-weight: 400;
      color: rgba($color: #000000, $alpha: 0.7);
      @include media-max(992px) {
        font-size: 1.4em;
      }
      @include media-max(500px) {
        font-size: 1.2em;
      }
    }
    h4 {
      margin: 30px 0;
      font-size: 0.9em;
      font-weight: 400;
      color: rgba($color: #000000, $alpha: 0.7);
      @include media-max(992px) {
        font-size: 0.75em;
        margin: 15px 0;
      }
    }
    h5 {
      font-size: 1.4em;
      font-weight: 400;
      margin-bottom: 10px;
      color: rgba($color: #000000, $alpha: 0.7);
      @include media-max(992px) {
        font-size: 1.2em;
      }
    }
    h6 {
      font-size: 1em;
      margin-bottom: 1rem;
      font-weight: 400;
      color: rgba($color: #000000, $alpha: 0.7);
      @include media-max(992px) {
        font-size: 0.85em;
      }
    }
    p {
      text-align: center;
      font-size: 0.9em;
      margin: 1rem 0;
      font-weight: 600;
      font-style: italic;
      color: rgba($color: #000000, $alpha: 0.7);
      @include media-max(992px) {
        font-size: 0.8em;
      }
    }
    img {
      width: 100%;
    }
    .grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
