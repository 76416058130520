.reset_password_form_page {
  .title {
    text-align: center;
    margin-bottom: 30px;
    h1 {
      color: #333e48;
      font-size: 1.786em;
      font-weight: 500;
    }
  }
  p.subtitle {
    text-align: center;
    font-size: 16px;
    @include media-max(600px) {
      font-size: 14px;
    }
  }
  form {
    width: 500px;
    margin: 50px auto 0 auto;
    @include media-max(600px) {
      width: 100%;
    }
    input {
      &:focus {
        border-color: $color-primary;
      }
      &:focus + label {
        color: $color-primary;
      }
    }
    button {
      background: $color-primary;
      margin: 20px auto 0 auto;
      &:hover {
        background: $color-primary;
        box-shadow: 0 8px 25px -8px $color-primary;
      }
    }
    &.new_password_form {
      .input_holder {
        margin-bottom: 30px;
      }
    }
  }
}
