.completion_purchase_screen {
  h1.title {
    text-align: center;
    margin-bottom: 50px;
    font-weight: 500;
    font-size: 34px;
    @include media-max(800px) {
      font-size: 24px;
    }
  }
  .form_inner {
    width: 100%;
    margin-bottom: 50px;
    form {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 550px;
      grid-gap: 0 50px;
      @include media-max(1600px) {
        grid-template-columns: 1fr 450px;
      }
      @include media-max(1200px) {
        grid-template-columns: 100%;
        grid-gap: 50px 0;
      }
      .row {
        display: grid;
        grid-gap: 40px;
        padding: 20px 0;
        input:focus {
          border-color: $color-primary;
        }
        input:focus + label {
          color: $color-primary;
        }
      }
      .two {
        grid-template-columns: repeat(2, 1fr);
        @include media-max(900px) {
          grid-template-columns: 100%;
        }
      }
      .company_row {
        grid-template-columns: 1fr 300px;
        @include media-max(900px) {
          grid-template-columns: 100%;
        }
      }
      .details_row {
        grid-template-columns: repeat(2, 1fr);
        //position: relative;
        @include media-max(900px) {
          grid-template-columns: 100%;
        }
        .single_row {
          position: relative;
        }
        .filteredStreets,
        .filteredCities {
          position: absolute;
          background-color: $color-white;
          z-index: 100;

          border: 1px solid black;
          padding: 0 20px;
          top: 38px;
          left: 0;

          max-height: 200px;
          overflow: auto;
          li {
            cursor: pointer;
          }
        }
        // select {
        //   border: 1px solid rgba(0, 0, 0, 0.2);
        //   border-radius: 5px;
        //   padding: 0 20px;
        //   //color: rgba(0, 0, 0, 0.4);
        //   //color: $color-text;
        //   width: 100%;
        //   height: 100%;
        // }
        // .select-normal {
        //   color: $color-text;
        // }
        // .select-disabled {
        //   color: rgba(0, 0, 0, 0.4);
        // }
        // select option:checked {
        //   color: $color-text;
        // }
      }
      .adventage_for_logged_users {
        margin: 20px 0;
        h5 {
          font-size: 15px;
          margin-bottom: 10px;
        }
        p {
          font-size: 14px;
        }
        ul {
          padding-left: 30px;
          li {
            list-style-type: disc;
            font-size: 14px;
            margin-top: 5px;
            a {
              list-style-type: disc;
            }
          }
        }
      }
      .additional_information {
        margin-top: 40px;
        h4 {
          font-weight: 500;
          font-size: 24px;
          border-bottom: 1px solid #dadada;
          position: relative;
          padding-bottom: 10px;
          margin-bottom: 20px;
          &::after {
            content: '';
            position: absolute;
            bottom: -1px;
            left: 0;
            width: 200px;
            height: 2px;
            background: $color-primary;
          }
        }
        label {
          font-weight: 600;
          font-size: 15px;
          display: block;
          margin-bottom: 10px;
        }
        textarea {
          width: 100%;
          height: 150px;
          border: 1px solid #ddd;
          color: $color-black;
          padding: 10px;
          border-radius: 6px;
          @include transition;
          &:focus {
            border-color: $color-primary;
          }
        }
      }
    }
  }
  .payment_method {
    width: 100%;
    border-radius: 10px;
    padding: 30px 20px;
    background: #f5f5f5;
    .your_bag {
      h2 {
        font-weight: 500;
        font-size: 24px;
        margin-bottom: 30px;
        padding-bottom: 10px;
        border-bottom: 1px solid #dadada;
        position: relative;
        @include media-max(600px) {
          font-size: 18px;
        }
        &::after {
          content: '';
          position: absolute;
          bottom: -1px;
          left: 0;
          width: 200px;
          height: 2px;
          background: $color-primary;
        }
      }
      .table {
        .table_header {
          border-bottom: 1px solid #dadada;
          padding: 0 0 10px 0;
        }
        .table_body {
          width: 100%;
          display: grid;
          grid-template-columns: 1fr 120px;
          padding: 20px 0;
          border-bottom: 1px solid #dadada;
          font-size: 14px;
          @include media-max(600px) {
            font-size: 12px;
          }
        }
      }
      .table_header {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 120px;
        padding-top: 10px;
        font-size: 14px;
        font-weight: 600;
        @include media-max(600px) {
          font-size: 12px;
        }
      }
    }
    form {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 120px;
      margin-bottom: 7px;
    }
    .promocode_input_and_button {
      display: flex;
      border-radius: 15px;
      border: solid 0.2px;
      align-items: center;
      justify-content: space-between;
      background-color: white;
      height: 50px;
      input {
        margin-left: 10px;
        text-align: center;
      }
      button {
        height: 95%;
        width: 120px;
        border-radius: 14px;
        margin: 2px;
        background-color: royalblue;
        color: white;
        cursor: pointer;
      }
    }
    .pdv {
      font-size: 14px;
      margin: 15px 0;
      padding-bottom: 10px;
      border-bottom: 3px solid #dadada;
      @include media-max(600px) {
        font-size: 12px;
      }
    }
    .privacy_policy {
      font-size: 14px;
      margin-bottom: 10px;
      @include media-max(600px) {
        font-size: 12px;
      }
      a {
        font-size: 14px;
        @include media-max(600px) {
          font-size: 12px;
        }
      }
    }
    .custom_button {
      margin-top: 20px;
      font-size: 16px;
      height: 40px;
      display: flex;
      justify-content: center;
      @include media-max(600px) {
        font-size: 14px;
      }
    }
  }
  .accept {
    font-size: 14px;
    @include media-max(600px) {
      font-size: 12px;
    }
    a {
      font-size: 14px;
      @include media-max(600px) {
        font-size: 12px;
      }
    }
  }
  .customized_accordions {
    margin: 20px 0;
    .MuiPaper-root {
      background: transparent;
    }
    .MuiAccordion-root.Mui-expanded {
      margin: 0;
      border-top: 1px solid #dadada;
      border-bottom: 1px solid #dadada;
    }
    .MuiPaper-elevation1 {
      box-shadow: unset;
    }
    p {
      font-size: 14px;
      @include media-max(600px) {
        font-size: 12px;
      }
      &.error {
        color: $color-danger;
      }
    }
    .MuiCollapse-wrapper {
      background: rgba(0, 0, 0, 0.035);
      .MuiAccordionDetails-root {
        padding: 20px 15px;
      }
    }
    @include media-max(800px) {
      .MuiTypography-body1 {
        font-size: 14px;
      }
    }
  }
}
