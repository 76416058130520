.table-invoices {
  .header,
  .table_row {
    display: grid;
    align-items: center;
    grid-template-columns: 40px 250px 1fr 170px 100px;
    @include media-max(1350px) {
      grid-template-columns: 40px 250px 1fr 170px 100px;
    }
    @include media-max(1000px) {
      grid-template-columns: 1fr;
    }
    .table_cell:nth-child(1) {
      a {
        color: $color-purple;
      }
    }
    .table_cell:last-child {
      position: relative;
      overflow: unset !important;
    }
  }
  .header {
    @include media-max(1000px) {
      padding-left: 45px !important;
      grid-template-columns: 250px 450px 100px;
      @include media-max(960px) {
        grid-template-columns: 250px 450px;
      }
      @include media-max(860px) {
        grid-template-columns: 200px 400px;
      }
      @include media-max(770px) {
        grid-template-columns: 1fr;
      }
      @include media-max(500px) {
        grid-template-columns: 100%;
      }
      .table_cell {
        &:nth-child(2) {
          display: flex !important;
        }
        &:nth-child(3) {
          display: flex !important;
          @include media-max(770px) {
            display: none !important;
          }
        }
        &:nth-child(4) {
          display: flex !important;
          @include media-max(960px) {
            display: none !important;
          }
        }
        display: none;
      }
    }
  }
  .table_row {
    cursor: pointer;
    @include media-max(1000px) {
      @include flex-align-center;
      flex-wrap: wrap;
      height: unset !important;
      padding: 10px !important;
      .expand_button {
        display: block;
      }
      .table_cell {
        &:nth-child(3) {
          width: 250px !important;
          display: flex !important;
          flex-direction: column;
          margin-left: 10px;
          @include media-max(860px) {
            width: 200px !important;
          }
        }
        &:nth-child(4) {
          width: 450px !important;
          display: flex !important;
          @include media-max(860px) {
            width: 400px !important;
          }
          @include media-max(770px) {
            display: none !important;
          }
        }
        &:nth-child(5) {
          width: 100px !important;
          display: flex !important;
          @include media-max(960px) {
            display: none !important;
          }
        }
        display: none;
      }
    }
  }
  .mobile_table_wrapper {
    @include media-max(1000px) {
      display: block;
      .mobile_table_row:nth-child(1) {
        display: none;
      }
      .mobile_table_row:nth-child(2) {
        display: none;
        @include media-max(770px) {
          display: grid;
        }
      }
      .mobile_table_row:nth-child(3) {
        display: none;
        @include media-max(960px) {
          display: grid;
        }
      }
    }
  }
}

.invoices_general_details_wrapper {
  .invoices_general_details_holder {
    .top_part {
      @include flex-between;
      border-bottom: 1px solid #f1f1f1;
      padding-bottom: 10px;
      margin-bottom: 10px;
      h2 {
        font-weight: 300;
        font-size: 20px;
        @include media-max(700px) {
          font-size: 18px;
        }
      }
    }
    .is_connected {
      border-bottom: 1px solid #f1f1f1;
      padding: 10px 0 20px 0;
      margin-bottom: 10px;
      font-weight: 300;
      font-size: 18px;
      @include media-max(700px) {
        font-size: 14px;
      }
      a {
        color: $color-purple;
        font-weight: 300;
        font-size: 18px;
        @include media-max(700px) {
          font-size: 14px;
        }
      }
    }
    .invoices_general_details_content {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 0 50px;
      @include media-max(1400px) {
        grid-template-columns: 100%;
        grid-gap: 50px 0;
      }
      .invoice_details,
      .internal_details {
        width: 100%;
        .detail_item {
          width: 100%;
          display: grid;
          grid-template-columns: 130px 1fr;
          align-items: flex-start;
          padding: 10px 0;
          grid-gap: 0 10px;
          @include media-max(500px) {
            grid-template-columns: 110px 1fr;
          }
          .label {
            font-weight: 500;
            color: #3a3a3a;
            margin-right: 5px;
            font-size: 14px;
            line-height: 1.6;
            @include media-max(500px) {
              font-size: 12px;
            }
          }
          .value {
            font-weight: 300;
            font-size: 14px;
            line-height: 1.6;
            @include media-max(1400px) {
              word-break: break-all;
            }
            @include media-max(500px) {
              font-size: 12px;
            }
          }
        }
      }
      .internal_details {
        .detail_item {
          grid-template-columns: 120px 1fr;
          @include media-max(1400px) {
            grid-template-columns: 130px 1fr;
          }
          @include media-max(500px) {
            grid-template-columns: 110px 1fr;
          }
        }
      }
    }
  }
}

.ivoices_info_internal_details {
  .detail_item {
    display: grid;
    grid-template-columns: 130px 1fr;
    height: 60px;
    grid-gap: 0 10px;
    align-items: center;
    font-size: 14px;
    line-height: 1.6;
    @include hover {
      .content_holder {
        .edit {
          opacity: 1;
        }
      }
    }
    @include media-max(750px) {
      font-size: 12px;
      grid-template-columns: 70px 1fr;
    }
    .value {
      font-weight: 300;
    }
    .content_holder {
      @include flex-align-center;
      button {
        margin-left: 5px;
      }
      .edit {
        @include transition;
        opacity: 0;
        @include media-max(600px) {
          opacity: 1;
        }
      }
    }
    input {
      width: 500px;
      font-size: 13px;
      border-radius: 5px;
      padding: 10px 20px;
      background: transparent;
      color: $color-text;
      @include border(1, rgba($color: $color-black, $alpha: 0.2));
      @include media-max(900px) {
        width: calc(100% - 50px);
        font-size: 12px;
        padding: 10px;
      }
    }
    .dropdown {
      width: 300px;
      height: 40px;
      @include border(1, rgba($color: $color-black, $alpha: 0.2));
      color: rgba($color: $color-black, $alpha: 0.4);
      border-radius: 5px;
      padding: 0 10px;
      position: relative;
      font-size: 14px;
      font-weight: 400;
      @include media-max(500px) {
        width: 100%;
        height: 38px;
        font-size: 12px;
      }
      .dropdown_selected {
        @include full-size;
        @include flex-between;
        cursor: pointer;
        color: $color-text;
        svg {
          color: rgba($color: $color-black, $alpha: 0.4);
        }
      }
      .dropdown_items {
        position: absolute;
        left: 0;
        right: 0;
        top: 110%;
        @include border(1, rgba($color: $color-black, $alpha: 0.2));
        background: $color-white;
        z-index: 2;
        box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        overflow: hidden;
        li {
          padding: 5px 10px;
          cursor: pointer;
          @include hover {
            background: $color-grey-10;
          }
          &.active {
            color: $color-text;
            background: $color-grey-10;
          }
        }
      }
    }
  }
}
