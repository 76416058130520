.credits {
  h4,
  li {
    color: rgba($color: $color-black, $alpha: 0.6);
  }
  .grid_title_text {
    display: grid;
    grid-template-columns: 150px 1fr;
    grid-gap: 0 40px;
    margin-bottom: 20px;
    @include media-max(768px) {
      grid-template-columns: 100%;
      grid-gap: 20px 0;
      justify-items: center;
    }
    img {
      width: 100%;
      @include media-max(768px) {
        width: 150px;
      }
    }
  }
  .grid_list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0 50px;
    @include media-max(768px) {
      grid-template-columns: 100%;
      grid-gap: 20px 0;
    }
    ul {
      margin-left: 20px;
      li {
        list-style-type: disc;
      }
    }
  }
  .table {
    @include border(1, $color-text);
    border-bottom: 0;
    margin: 50px 0;
    .row {
      p:first-child {
        border-right: 1px solid $color-text;
      }
      border-bottom: 1px solid $color-text;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      align-items: center;
      font-size: 14px;
      &.header {
        font-weight: 700;
      }
      p {
        margin: 0;
        padding: 10px;
      }
    }
  }
}
