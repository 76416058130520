.form_field {
  width: 100%;
  height: 40px;
  position: relative;
  input {
    z-index: 1;
    position: relative;
    font-size: 13px;
    border-radius: 5px;
    padding: 0 20px;
    background: transparent;
    color: $color-text;
    @include transition;
    @include full-width;
    @include border(1, rgba($color: $color-black, $alpha: 0.2));
    @include placeholder {
      color: rgba($color: $color-black, $alpha: 0.4);
    }
    &:focus {
      box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
      border-color: $color-purple;
      & + label {
        transform: translate(-10px, -35px);
        color: $color-purple;
        font-size: 0.7rem;
      }
    }
    &:not([value='']) + label {
      transform: translate(-10px, -35px);
      font-size: 0.7rem;
    }
  }
  input[type='date'] + label {
    transform: translate(-10px, -35px);
    font-size: 0.7rem;
  }
  label {
    position: absolute;
    top: 0;
    left: 0;
    @include transition;
    @include full-width;
    @include flex-align-center;
    padding: 0 20px;
    color: rgba($color: $color-black, $alpha: 0.4);
    white-space: nowrap;
  }
}
.error_msg {
  margin-top: 5px;
}
