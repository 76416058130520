.icons_nav_wrap {
  background: #f8f9fa;
  padding: 10px 150px;
  @include media-max(1000px) {
    display: none;
  }

  .container {
    .icons_nav_holder {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include media-max(1200px) {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 30px;
      }
      @include media-max(1000px) {
        grid-gap: 20px;
      }
      .item {
        display: flex;
        align-items: center;
        color: black;
        gap: 5px;
        h3 {
          font-size: 14px;
        }
        svg {
          height: 20px;
          width: 20px;
        }
      }
    }
  }
}
