@import './preview_news';

.news_page {
  margin-bottom: 50px;
  img {
    width: 100%;
  }
  .banner {
    margin-top: 50px;
    display: block;
    cursor: pointer;
  }
  .cards {
    margin-top: 100px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 0 50px;
    @include media-max(992px) {
      grid-template-columns: 100%;
      grid-gap: 30px 0;
    }
    .card {
      width: 100%;
      a {
        display: block;
      }
      ul {
        margin: 20px 0 0 20px;
        li {
          list-style-type: disc;
          color: rgba(0, 0, 0, 0.7);
          font-size: 13px;
          line-height: 1.6em;
        }
      }
    }
  }
  .slider {
    margin: 50px 0;
    .swiper-container {
      padding-bottom: 50px;
    }
    .slide {
      @include border(1, #ebebeb);
      padding: 20px;
      .photo {
        @include inline-bg;
        height: 300px;
        @include media-max(992px) {
          height: 250px;
        }
        @include media-max(700px) {
          height: 200px;
        }
      }
      .details {
        padding: 20px 0 0 0;
        .around {
          @include flex-align-center;
          justify-content: space-around;
        }
        h3 {
          margin: 20px 0;
          font-weight: 500;
          font-size: 1.3rem;
          color: rgba(0, 0, 0, 0.7);
          text-align: center;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        p {
          color: rgba(0, 0, 0, 0.7);
          line-height: 1.7;
          font-size: 13px;
        }
        p.text {
          height: 70px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .line {
          border-bottom: 1px solid #ebebeb;
          padding-bottom: 20px;
          margin-bottom: 20px;
        }
        button {
          width: 130px;
          height: 45px;
          border-radius: $radius-pill;
          font-size: 14px;
          background: $color-primary;
          color: $color-white;
          @include flex-center;
          margin-left: auto;
          font-weight: 600;
          @include hover {
            opacity: 0.7;
          }
        }
      }
    }
  }
}
