// Reset

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
}

body {
  line-height: $font-line-height-sm;
  color: $color-text;
  background-color: $color-white;
  transition: all 0.3s ease-in-out;
  overflow-x: hidden;
  position: relative;
  left: 0;
  font-family: 'Poppins', sans-serif;
}

button,
select,
textarea,
input {
  border: 0;
  font-family: $font-roboto;
}

.show_apparence {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: button;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type='number'] {
  -moz-appearance: textfield;
}

textarea {
  resize: none;
}

button {
  cursor: pointer;
}

ol,
li {
  list-style-type: none;
}

a {
  text-decoration: none;
  font-size: $font-size-body;
  color: $color-primary;
}

.container {
  margin: 0 auto;
  padding: 0 0.9375rem;
  width: 100%;
  @media (min-width: 544px) {
    max-width: 576px;
  }
  @media (min-width: 768px) {
    max-width: 720px;
  }
  @media (min-width: 992px) {
    max-width: 940px;
  }
  @media (min-width: 1200px) {
    max-width: 1140px;
  }
  @media (min-width: 1200px) {
    max-width: 1200px;
  }
  @media (min-width: 1480px) {
    max-width: 1430px;
  }
  @media (min-width: 1800px) {
    max-width: 1550px;
  }
}

.notification_style {
  padding: 1rem;
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
  border-radius: 0 0.428rem 0.428rem 0;
  z-index: 99999;
  font-weight: 500;
  background: $color-white;
  color: #6e6b7b;
  font-weight: 400;
  font-size: 0.9em;
  @include flex-align-center;
  border-left: 3px solid transparent;
  &.success {
    border-color: #28c76f;
    span {
      color: #28c76f;
    }
  }
  &.danger {
    border-color: #ea5455;
    span {
      color: #ea5455;
    }
  }
  span {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    @include flex-center;
    // background: #28c76f;
    margin-right: 1rem;
    color: $color-white;
    svg {
      width: 25px;
      height: 25px;
      font-size: 24px;
    }
  }
}

.error_msg {
  color: $color-danger;
  font-size: 14px;
}
