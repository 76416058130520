footer#footer {
  background-color: #202020;
  color: $color-white;
  font-size: 0.875em;
  letter-spacing: -0.01em;
  line-height: 1.714em;
  margin-top: 20px;
  .container {
    .footer_top_side {
      padding: 30px 0 3.2145em 0;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 0 30px;
      @include media-max(1479px) {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 30px;
      }
      @include media-max(991px) {
        @include flex-column;
        padding-bottom: 0;
      }
      .title_holder {
        width: 100%;
        margin-bottom: 40px;
        h2 {
          font-size: 1.429em;
          padding: 15px 0;
          font-weight: 500;
          line-height: 1.1;
          @include media-max(991px) {
            padding: 0;
          }
          &.mobile {
            display: none;
          }
        }
        .line {
          width: 100%;
          height: 1px;
          background: #dadada;
        }
      }
      .general {
        @include media-max(991px) {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
        p {
          margin-bottom: 1rem;
          @include media-max(991px) {
            display: none;
          }
        }
        img {
          margin-bottom: 40px;
          width: 270px;
          @include media-max(1479px) {
            width: 205px;
          }
          @include media-max(991px) {
            margin: 0;
            width: 225px;
          }
        }
      }
      .contact {
        @include media-max(991px) {
          order: 3;
          .title_holder {
            margin: 0;
            h2 {
              @include flex-between;
              cursor: pointer;
              font-size: 16px;
              &.mobile {
                display: block;
                @include flex-between;
              }
              &.web {
                display: none;
              }
            }
            .line {
              display: none;
            }
          }
        }
        .mobile_accordian {
          display: none;
          @include media-max(991px) {
            border-radius: 8px;
            background: #203f63;
            height: 0;
            padding: 0 20px;
            height: 0;
            display: block;
            animation: accordian_disable 0.3s linear forwards;
            overflow: hidden;
            .row {
              display: none;
            }
            &.active {
              animation: accordian 0.3s linear forwards;
              .row {
                @include flex-align-center;
              }
            }
          }
        }
        .web_accordian {
          @include media-max(991px) {
            display: none;
          }
        }
        .row {
          @include flex-align-center;
          margin-bottom: 20px;
          img {
            margin-right: 1.618em;
          }
          a {
            color: $color-white;
            font-size: 14px;
          }
          span {
            margin: 0 8px;
          }
        }
      }

      .useful_links {
        @include media-max(991px) {
          order: 4;
          .title_holder {
            margin: 0;
            h2 {
              @include flex-between;
              cursor: pointer;
              font-size: 16px;
              &.mobile {
                display: block;
                @include flex-between;
              }
              &.web {
                display: none;
              }
            }
            .line {
              display: none;
            }
          }
        }
        .mobile_accordian {
          display: none;
          @include media-max(991px) {
            @include flex-column;
            border-radius: 8px;
            background: #203f63;
            height: 0;
            padding: 0 20px;
            height: 0;
            animation: accordian_disable2 0.3s linear forwards;
            overflow: hidden;
            &.active {
              animation: accordian2 0.3s linear forwards;
            }
          }
        }
        .web_accordian {
          @include flex-column;
          @include media-max(991px) {
            display: none;
          }
        }
        a {
          color: $color-white;
          margin-bottom: 3px;
          font-size: 14px;
        }
      }

      .newsletter {
        @include media-max(991px) {
          order: 2;
          @include flex-column-center;
          .title_holder {
            margin: 0;
            h2 {
              text-align: center;
              font-size: 14px;
              margin-bottom: 20px;
            }
            .line {
              display: none;
            }
          }
        }
        .input_wrap {
          width: 100%;
          height: 45px;
          border-radius: 0.357rem;
          display: grid;
          grid-template-columns: 1fr 87px;
          overflow: hidden;
          input {
            @include full-size;
            font-size: 14px;
            color: #818181;
            padding: 0 1.214em;
            background: $color-white;
          }
          button {
            @include full-size;
            @include flex-center;
            background: $color-primary;
            color: $color-white;
            font-size: 14px;
            @include hover {
              opacity: 0.9;
            }
          }
        }
        h4 {
          margin: 20px 0;
          font-size: 1.5rem;
          font-weight: 500;
          line-height: 1.1;
          @include media-max(991px) {
            font-size: 14px;
            margin: 20px 0 5px 0;
            font-weight: 600;
            display: none;
          }
        }
        p {
          @include media-max(991px) {
            font-size: 12px;
            display: none;
          }
        }
        button.submit {
          margin-top: 20px;
          width: 170px;
          height: 40px;
          border-radius: 0.357rem;
          background: $color-primary;
          color: $color-white;
          font-size: 14px;
          font-weight: 600;
          a {
            color: white;
          }
          @include flex-center;
          @include hover {
            background: $color-white;
            a {
              color: $color-primary;
            }
          }
          @include media-max(991px) {
            display: none;
          }
        }
      }
    }
  }
  .footer_bottom_side {
    background-color: #000;
    padding: 30px 0;
    @include media-max(991px) {
      background-color: #202020;
      padding-top: 0;
    }
    .container {
      .footer_bottom_side_holder {
        .newsletter {
          display: none;
          @include media-max(991px) {
            @include flex-column-center;
          }
          .title_holder {
            display: none;
          }
          .input_wrap {
            display: none;
          }
          h4 {
            font-size: 14px;
            margin: 20px 0 5px 0;
            font-weight: 600;
          }
          p {
            @include media-max(991px) {
              font-size: 12px;
            }
          }
          button.submit {
            margin: 20px 0;
            width: 190px;
            height: 48px;
            border-radius: 0.357rem;
            background: $color-primary;
            color: $color-white;
            font-size: 14px;
            font-weight: 600;
            a {
              color: $color-white;
            }
            @include flex-center;
            @include hover {
              background: $color-white;
              a {
                color: $color-primary;
              }
            }
          }
        }
        nav {
          width: 100%;
          @include flex-center;
          a {
            color: $color-white;
            margin: 0 10px;
            font-size: 0.875em;
            position: relative;
            &::after {
              content: '|';
              position: absolute;
              top: 0;
              bottom: 0;
              right: -10px;
            }
            &:last-child {
              &::after {
                display: none;
              }
            }
          }
        }
        .footer_bottom_side_content {
          margin-top: 30px;
          @include flex-between;
          @include media-max(991px) {
            @include flex-column-center;
          }
          .copy_rights {
            font-size: 0.875em;
            @include media-max(991px) {
              order: 3;
            }
          }
          ul.list_of_links {
            @include flex-center;
            @include media-max(991px) {
              margin: 30px 0;
              order: 1;
              margin: 0;
            }
            li {
              width: 26px;
              height: 26px;
              background: $color-white;
              border-radius: $radius-round;
              margin: 0 7px;
              a {
                @include full-size;
                @include flex-center;
                color: #203f63;
              }
            }
          }
          .payment_details {
            @include flex-column-center;
            p {
              margin-top: 5px;
              font-size: 11px;
            }
            @include media-max(991px) {
              order: 2;
              margin: 20px 0;
            }
          }
        }
      }
    }
  }
}
