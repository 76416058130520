.partners_slider_dialog_wrap {
  @include flex-center;
  @include fixed-full;
  background: rgba(0, 0, 0, 0.7);
  z-index: 9999;
  .partners_slider_dialog_holder {
    background: $color-white;
    padding: 30px;
    display: grid;
    grid-template-rows: 1fr 30px;
    align-items: center;
    grid-gap: 30px 0;
    justify-items: center;
    border-radius: 4px;
    @include media-max(700px) {
      padding: 20px;
      grid-gap: 20px 0;
      grid-template-rows: 1fr 20px;
    }
    button {
      position: absolute;
      top: 10px;
      right: 30px;
      background: $color-danger;
      color: $color-white;
      @include media-max(700px) {
        width: 20px;
        height: 20px;
        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
    img {
      height: 350px;
      @include media-max(1200px) {
        height: 300px;
      }
      @include media-max(700px) {
        height: 200px;
      }
      @include media-max(500px) {
        height: 170px;
      }
    }
    p {
      font-size: 16px;
      @include media-max(700px) {
        font-size: 14px;
      }
      @include media-max(500px) {
        font-size: 12px;
      }
    }
  }
}
