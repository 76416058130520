.contact_form {
  margin: 30px 0;
  width: 100%;
  @include flex-center;
  form {
    width: 500px;
    @include flex-column;
    @include media-max(600px) {
      width: 100%;
    }
    textarea,
    input {
      display: block;
      width: 100%;
      height: 45px;
      border-radius: $radius-pill;
      @include border(2, $color-primary);
      font-size: 14px;
      padding: 0 20px;
      margin-bottom: 20px;
      color: rgba(0, 0, 0, 0.7);
    }
    textarea {
      height: 150px;
      border-radius: 10px;
      padding: 10px;
    }
    button {
      max-width: 120px;
      height: 40px;
      border-radius: $radius-pill;
      color: $color-white;
      background: $color-primary;
      @include flex-center;
      font-weight: 700;
      @include hover {
        background: $color-black;
      }
    }
    .row {
      margin: 30px 0;
    }
  }
}
