.contact_us_page {
  img {
    width: 100%;
  }
  .banner {
    display: block;
    margin: 30px 0;
    cursor: pointer;
  }
  h1 {
    text-align: center;
    color: $color-primary;
    font-weight: 600;
  }
  .locations {
    margin-top: 30px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 50px;
    @include media-max(768px) {
      grid-template-columns: 100%;
      grid-gap: 50px 0;
    }
    .item {
      color: rgba(0, 0, 0, 0.7);
      h3 {
        color: $color-primary;
        font-weight: 600;
        font-size: 1.6rem;
        margin-bottom: 20px;
        @include media-max(500px) {
          font-size: 1.2rem;
        }
      }
      ul {
        li {
          display: grid;
          grid-template-columns: 140px 1fr;
          margin-bottom: 10px;
          @include media-max(500px) {
            font-size: 13px;
          }
          a {
            color: rgba(0, 0, 0, 0.7);
            @include media-max(500px) {
              font-size: 13px;
            }
          }
        }
      }
    }
  }
}
