.newsletter_unsubscribe_screen {
  @include fixed-full;
  background: $color-grey-10;
  z-index: 99999999;
  @include flex-column;
  align-items: center;
  padding: 20px;
  h1 {
    color: rgb(185, 185, 185);
    font-size: 50px;
    font-weight: 300;
    border-bottom: 1px solid rgb(185, 185, 185);
    margin-bottom: 100px;
    text-align: center;
    @include media-max(800px) {
      font-size: 32px;
      margin-bottom: 50px;
    }
    @include media-max(500px) {
      font-size: 24px;
    }
  }
  span {
    @include flex-center;
    color: $color-white;
    background: $color-secondary;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    &.success {
      background: $color-success;
    }
    &.error {
      background: $color-danger;
    }
    @include media-max(800px) {
      width: 60px;
      height: 60px;
    }
    svg {
      width: 50px;
      height: 50px;
      font-size: 50px;
      @include media-max(800px) {
        width: 40px;
        height: 40px;
      }
    }
  }
  h2 {
    margin: 30px 0;
    font-weight: 500;
    font-size: 28px;
    color: rgb(185, 185, 185);
    text-align: center;
    @include media-max(800px) {
      font-size: 17px;
      margin: 20px 0;
    }
  }
  a.custom_button {
    width: 200px;
  }
}
