.equip_office_page {
  .row {
    display: grid;
    grid-template-columns: 1fr 450px;
    align-items: center;
    grid-gap: 0 50px;
    margin-bottom: 50px;
    @include media-max(1200px) {
      grid-template-columns: 1fr 400px;
    }
    @include media-max(992px) {
      grid-template-columns: 1fr 300px;
      grid-gap: 0 20px;
    }
    @include media-max(768px) {
      @include flex-column-center;
    }
    &.reverse {
      grid-template-columns: 450px 1fr;
      @include media-max(1200px) {
        grid-template-columns: 400px 1fr;
      }
      @include media-max(992px) {
        grid-template-columns: 300px 1fr;
      }
      @include media-max(768px) {
        .text {
          order: 1;
        }
        img {
          order: 2;
        }
      }
    }
    .text {
      @include media-max(768px) {
        margin-bottom: 30px;
      }
      h1 {
        text-transform: uppercase;
        color: $color-primary;
        font-weight: bold;
        font-size: 1.6rem;
        margin-bottom: 1rem;
        @include media-max(768px) {
          font-size: 1.3rem;
        }
      }
      h3 {
        text-transform: uppercase;
        color: $color-primary;
        font-weight: bold;
        font-size: 1.6rem;
        margin-bottom: 1rem;
        @include media-max(768px) {
          font-size: 1.3rem;
        }
      }
      p {
        color: rgba(0, 0, 0, 0.7);
        font-size: 1rem;
        line-height: 1.6;
      }
      ul {
        margin: 20px 0 0 20px;
        li {
          list-style-type: disc;
          font-weight: bold;
          margin-bottom: 0.5rem;
        }
      }
    }
    img {
      width: 100%;
    }
  }
  .contact_form {
    display: block;
    .left_side {
      .two {
        display: block;
      }
    }
  }
}
