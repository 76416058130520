.cms_notifications_screen {
  .emptylist {
    text-align: center;
    font-size: 18px;
    padding: 20px;
  }
  .helper_buttons {
    @include flex-align-center;
    button {
      margin-right: 20px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .table-notifications {
    .header,
    .table_row {
      display: grid;
      grid-template-columns: 50px 350px 1fr 100px;
      align-items: center;
      @include media-max(1100px) {
        grid-template-columns: 50px 200px 1fr 100px;
      }
      @include media-max(700px) {
        grid-template-columns: 50px 100px 1fr 100px;
      }
    }
    .table_row {
      cursor: pointer;
    }
    .align {
      @include flex-align-center;
      justify-content: flex-end;
      span.status {
        width: 10px;
        height: 10px;
        display: block;
        border-radius: 50%;
        background: $color-purple;
        margin-right: 20px;
      }
    }
    .icon {
      width: 35px;
      height: 35px;
      border-radius: 50%;
      @include flex-center;
      svg {
        width: 50%;
        height: 50%;
      }
      &.success {
        background: rgba(40, 199, 111, 0.12);
        color: #28c76f;
      }
      &.error {
        background: rgba(234, 84, 85, 0.12);
        color: #ea5455;
      }
    }
  }
}

.notifications_modal_wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba($color: $color-black, $alpha: 0.3);
  @include flex-center;
  z-index: 9999;
  .notifications_modal_holder {
    background: $color-white;
    padding: 20px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    max-width: 500px;
    .icon {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      @include flex-center;
      svg {
        width: 50%;
        height: 50%;
      }
      &.success {
        background: rgba(40, 199, 111, 0.12);
        color: #28c76f;
      }
      &.error {
        background: rgba(234, 84, 85, 0.12);
        color: #ea5455;
      }
    }
    h2 {
      margin: 20px 0;
      font-size: 20px;
      font-weight: 500;
    }
    h3 {
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 20px;
    }
  }
}
