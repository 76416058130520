.etrust_page {
  background-color: white;
  margin-bottom: 50px;
  img {
    width: 100%;
  }
  .title {
    font-family: $font-roboto;
    font-size: 1.3vw;
    margin: 3% 0 3% 0;
    font-weight: bold;
    padding: 0 20% 0 20%;
    @include media-max(992px) {
      padding: 5%;
      font-size: 5vw;
    }
  }
  .http_tab {
    padding: 5% 30% 5% 30%;
    background-color: $color-primary;
    @include media-max(992px) {
      padding: 5% 10% 5% 10%;
    }

    .text {
      text-align: center;
      font-size: 2vw;
      font-family: $font-roboto;
      color: white;
      @include media-max(992px) {
        font-size: 4vw;
      }
    }
  }
  .paragraph {
    font-family: 'Segoe UI';
    padding: 0 20% 0 20%;
    font-size: 0.8vw;
    font-family: $font-roboto;
    @include media-max(992px) {
      padding: 2%;
      font-size: 3vw;
    }
  }
  .http_image {
    padding: 0 35% 0 35%;
    @include media-max(992px) {
      padding: 0 25% 0 25%;
    }
  }

  .trustmark {
    display: flex;
    padding: 2% 60% 2% 20%;
    @include media-max(992px) {
      padding: 5% 25% 5% 25%;
    }
    width: 100%;
  }
}
