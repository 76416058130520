.mobile_bar {
  display: none;
  border-bottom: 1px solid #ddd;
  width: 100%;
  padding: 20px 0;
  transition: all 0.3s ease-in-out;
  @include media-max(1200px) {
    @include flex-align-center;
  }
  // &.active {
  //   position: fixed;
  //   top: 0;
  //   left: 0;
  //   right: 0;
  //   background: $color-white;
  //   animation: sticky_slow_down 0.25s forwards;
  //   z-index: 999;
  // }
  .container {
    max-width: 100%;
    .mobile_bar_holder {
      @include flex-between;
      svg {
        width: 30px;
        height: 30px;
        font-size: 20px;
      }
      .left {
        @include flex-align-center;
        img {
          width: 150px;
          cursor: pointer;
        }
      }
      .right {
        @include flex-align-center;
        height: 20px;
        grid-gap: 12px;
        margin-right: 7px;
        .item {
          display: flex;
          flex-direction: column;
          align-items: center;
          color: black;
          p {
            font-size: 12px;
            font-weight: 500;
          }
        }
        .cart_icon {
          position: relative;

          .cart_badge {
            position: absolute;
            top: -5px;
            right: -8px;
            width: 16px;
            height: 16px;
            font-size: 10px;
            color: $color-white;
            background: $color-primary;
            border-radius: 50%;
            @include flex-center;
            text-align: center;
          }
        }
      }
    }
  }
}
