.service_page {
  img {
    width: 100%;
  }
  .banner_service {
    margin-bottom: 100px;
    display: block;
    cursor: pointer;
  }
  .wrapper {
    display: grid;
    grid-template-columns: 1fr 300px;
    grid-gap: 0 50px;
    margin: 50px 0;
    @include media-max(768px) {
      grid-template-columns: 100%;
      grid-gap: 50px 0;
      justify-items: center;
    }
    .content {
      h1 {
        margin-bottom: 1em;
        font-weight: 500;
        font-size: 2em;
      }
      p {
        margin-bottom: 1em;
        font-size: 1em;
        line-height: 1.7;
        color: rgba(0, 0, 0, 0.7);
      }
      h4 {
        font-size: 1.7em;
        margin-bottom: 1em;
        font-weight: 500;
      }
      ul {
        margin: 0 0 20px 20px;
        li {
          list-style-type: disc;
          font-size: 1em;
          line-height: 1.7;
          color: rgba(0, 0, 0, 0.7);
        }
      }
      span {
        color: #9fc95a;
      }
      .return {
        display: grid;
        grid-template-columns: 150px 1fr;
        grid-gap: 0 50px;
        align-items: center;
        margin: 50px 0;
        div {
          font-size: 1em;
          line-height: 1.7;
          color: rgba(0, 0, 0, 0.7);
          @include media-max(992px) {
            font-size: 14px;
          }
        }
        @include media-max(992px) {
          grid-template-columns: 100%;
          grid-gap: 20px 0;
          justify-items: center;
          img {
            width: 100px;
          }
        }
      }
      .info {
        background: #f2f2f2;
        padding: 30px 20px;
        line-height: 1.6;
        @include media-max(992px) {
          font-size: 14px;

          a {
            padding: 20px 5px;
            font-size: 14px;
          }
        }
      }
    }
    .navigation_cards_wrap {
      h2 {
        margin-bottom: 20px;
        font-weight: 500;
        font-size: 1.3em;
        text-align: center;
      }
      a {
        display: block;
        margin-bottom: 30px;
        @include media-max(768px) {
          width: 250px;
        }
      }
    }
  }
  .contact_service {
    width: 500px;
    margin: 0 auto;
    @include media-max(600px) {
      width: 100%;
    }
    .info {
      @include border(2, $color-primary);
      padding: 20px;
      border-radius: 10px;
      font-size: 1em;
      line-height: 1.6;
      color: rgba(0, 0, 0, 0.7);
      @include media-max(600px) {
        font-size: 14px;
      }
    }
    .contact_form {
      border-radius: 10px;
      background: $color-grey-10;
      padding: 20px;
    }
    .return {
      display: grid;
      grid-template-columns: 100px 1fr;
      grid-gap: 0 20px;
      align-items: center;
      margin: 50px 0;
      font-size: 1em;
      line-height: 1.6;
      color: rgba(0, 0, 0, 0.7);
      @include media-max(600px) {
        grid-template-columns: 100%;
        grid-gap: 30px 0;
        font-size: 14px;
        justify-items: center;
        img {
          width: 100px;
        }
      }
    }
  }
}
