.image_banner_wrapper {
  margin: 50px 0;
  display: block;
  cursor: pointer;
  img {
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
  }
}
