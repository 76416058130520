.mobile_search_dialog_wrap {
  display: none;
  @include media-max(1024px) {
    display: block;
  }
  z-index: 100;
  background: $color-white;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
  .mobile_search_dialog_holder {
    @include full-size;
    @include flex-center;
    position: relative;
    padding: 20px;
    overflow: hidden;
    .menu {
      height: 30px;
      width: 30px;
      margin-right: 20px;
    }
    .close_icon {
      position: absolute;
      top: 5px;
      right: 5px;
      color: $color-danger;
      font-size: 20px;
    }
    .search_wrapper {
      width: 100%;
      height: 38px;
      display: grid;
      grid-template-columns: 1fr 50px 100px;
      border: 1px solid $color-primary;
      border-radius: 6px;
      overflow: hidden;
      border-radius: 200px;
      z-index: 999;
      @include media-max(600px) {
        grid-template-columns: 1fr 40px 70px;
      }
      span {
        @include full-size;
        @include flex-center;
        color: #bebebe;
      }
      input {
        @include full-size;
        padding: 0 10px;

        @include media-max(600px) {
          font-size: 12px;
        }
      }
      button {
        border: 0;
        background: $color-primary;
        color: $color-white;
        font-size: 14px;
        font-weight: 600;
        border-radius: 200 0 0 200px;
        @include media-max(600px) {
          font-size: 12px;
        }
      }
      .microfone_off {
        display: flex;
        background-color: white;
        color: $color-primary;
        border-left: 1px solid $color-primary;
      }
    }
  }
}
