#app_bar {
  header.header {
    background: $color-white;
    border-bottom: 1px solid #ddd;
    padding: 15px 0;
    @include media-max(1200px) {
      display: none;
    }
    &.active {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 100;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      padding: 20px 0;
      animation: sticky_slow_down 0.25s forwards;
    }
    .container {
      .header_content {
        display: grid;
        align-items: center;
        grid-template-columns: 250px 1fr auto;
        justify-content: space-between;
        grid-gap: 150px;
        @include media-max(1480px) {
          grid-gap: 20px;
        }
        .left_side {
          // width: 260px;
          // margin-left: 2rem;
          margin-bottom: -15px;
          @include flex-align-center;
          a.logo_link {
            @include flex-center;
            img {
              width: 100%;
            }
          }
        }
        .middle {
          display: grid;
          align-items: center;
          grid-gap: 30px;
          grid-template-columns: 1fr 100px;
          .search_wrapper {
            width: 100%;
            height: 42px;
            display: grid;
            grid-template-columns: 1fr 50px 100px;
            //overflow: hidden;
            border-radius: 200px;
            background: $color-white;
            z-index: 101;
            @include media-max(1050px) {
              grid-template-columns: 50px 25px 50px;
            }
            &:focus-within {
              box-shadow: 0 3px 10px 0 rgba(34, 41, 47, 0.1);
            }
            span {
              @include full-size;
              @include flex-center;
              color: $color-primary;
              border: 1px solid $color-primary;
              border-radius: 200px;
              background: transparent;
              cursor: pointer;
            }
            button {
              @include full-size;
              @include flex-center;
              background: $color-primary;
              color: $color-white;
              font-weight: 400;
              font-size: 14px;
              border-radius: 0 200px 200px 0;
              border: 1px solid transparent;
              border-left: 0;
              @include hover {
                background: $color-white;
                color: $color-primary;
                border-color: $color-primary;
              }
            }
            .microfone_off {
              width: 100%;
              height: 100%;
              display: flex;
              justify-content: center;
              background-color: white;
              color: $color-primary;
              border-radius: 0;
              border-top: 1px solid $color-primary;
              border-bottom: 1px solid $color-primary;
            }
            .microfone:hover {
              background-color: $color-grey-09;
              color: $color-primary;
            }
            .microfone_on {
              background-color: $color-primary;
              color: white;
              border-radius: 0;
              border: 1px solid $color-primary;
            }
            input {
              @include full-size;
              padding: 0 15px;
              text-indent: 0px;
              border: 1px solid $color-primary;
              background: transparent;
              border-radius: 200px 0px 0px 200px;
              @include transition;
              color: #6e6b7b;
              font-weight: 400;
              &:focus {
                text-indent: 8px;
              }
            }
          }
          .phone_numbers {
            a {
              display: block;
              margin-bottom: 2px;
              &:last-child {
                margin-bottom: 0;
              }
              font-size: 14px;
              color: $color-primary;
              font-weight: 400;
              @include hover {
                text-decoration: underline;
              }
            }
          }
        }
        .right_side {
          display: flex;
          align-items: center;
          justify-content: end;
          grid-gap: 30px;
          svg {
            @include full-size;
            font-size: 100%;
            color: #333e48;
            cursor: pointer;
            @include hover {
              opacity: 0.3;
            }
            &.active {
              color: $color-danger;
            }
          }
          .favorite_link {
            height: 27px;
            p {
              font-size: 14px;
              color: black;
            }
          }
          .account_part {
            height: 27px;
            position: relative;
            p {
              font-size: 14px;
              color: black;
            }
            .account_part_modal {
              animation: slidedown 0.2s linear forwards;
              position: absolute;
              top: calc(100% + 8px);
              left: -150px;
              width: 220px;
              background: $color-white;
              z-index: 1;
              box-shadow: 0 5px 25px rgba(34, 41, 47, 0.1);
              border-radius: 0.358rem;
              border: 1px solid #ebe9f1;
              overflow: hidden;
              & > div {
                padding: 10px 0;
                @include flex-column-center;
                &.top_side {
                  a {
                    background: $color-primary;
                    color: $color-white;
                    font-weight: 600;
                    font-size: 12px;
                    padding: 5px 8px;
                    border-radius: 4px;
                    margin-top: 10px;
                    @include hover {
                      background: $color-black;
                    }
                  }
                }
                &.bottom_side {
                  border-top: 1px solid $color-grey-01;
                  padding-top: 10px;
                  margin-top: 10px;
                  a {
                    margin-top: 7px;
                    font-size: 12px;
                    color: $color-blue-03;
                    @include hover {
                      color: $color-blue-01;
                    }
                  }
                }
              }
              p {
                font-size: $font-size-sm;
              }
              .is_auth_dropdown {
                a,
                button {
                  width: 100%;
                  background: $color-white;
                  color: $color-text;
                  font-size: 15px;
                  padding: 7px 20px;
                  @include flex-align-center;
                  @include hover {
                    background: $color-grey-09;
                  }
                }
              }
            }
          }
          .cart_part {
            position: relative;
            height: 27px;
            p {
              margin-top: 6px;
              font-size: 14px;
              color: black;
            }
            .row {
              @include full-size;
              cursor: pointer;
              .icon_holder {
                @include full-size;
                @include flex-center;
                span {
                  position: absolute;
                  top: -10px;
                  right: -10px;
                  width: 22px;
                  height: 22px;
                  border-radius: $radius-round;
                  background: $color-primary;
                  color: $color-white;
                  font-weight: 500;
                  font-size: $font-size-sm;
                  @include flex-center;
                }
              }
              h4.price {
                font-size: 15px;
                font-weight: bold;
                color: $color-text;
              }
            }
            .shopping_cart_dropdown_inner {
              position: absolute;
              background: $color-white;
              top: calc(100% + 10px);
              right: 0;
              width: 30rem;
              box-shadow: 0 5px 25px rgba(34, 41, 47, 0.1);
              border-radius: 0.358rem;
              border: 1px solid #ebe9f1;
              z-index: 3;
              .shopping_cart_dropdown_outer {
                .header {
                  padding: 1.22rem 1.28rem;
                  border-bottom: 1px solid #ebe9f1;
                  h3 {
                    font-weight: 500;
                    color: #5e5873;
                    font-size: 1.2rem;
                  }
                }
                .holder {
                  overflow-y: scroll;
                  max-height: 354px;
                  &::-webkit-scrollbar {
                    width: 7px;
                  }
                  &::-webkit-scrollbar-track {
                    background: #f8f8f8;
                  }
                  &::-webkit-scrollbar-thumb {
                    background: #aeaeae;
                    border-radius: 50px;
                  }
                  .no_result {
                    padding: 1.22rem 1.28rem;
                    color: #5e5873;
                    font-weight: 400;
                    font-size: 14px;
                  }
                  .item {
                    border-bottom: 1px solid #ebe9f1;
                    padding: 10px 0;
                    &:last-child {
                      border-bottom: 0;
                    }
                    @include hover {
                      background: #f8f8f8;
                      .content {
                        .remove {
                          opacity: 1;
                        }
                      }
                    }
                    .content {
                      display: grid;
                      grid-template-columns: 1fr 100px 100px;
                      grid-gap: 0 10px;
                      align-items: center;
                      position: relative;
                      .remove {
                        position: absolute;
                        top: -3px;
                        right: 3px;
                        color: #5e5873;
                        font-size: 16px;
                        width: 16px;
                        height: 16px;
                        cursor: pointer;
                        opacity: 0;
                      }
                      .name_and_quantity {
                        width: 100%;
                        display: grid;
                        grid-template-columns: 50px 1fr;
                        grid-gap: 10px;
                        align-items: center;
                        .photo {
                          width: 100%;
                          height: 50px;
                          @include flex-center;
                          img {
                            width: 40px;
                            height: 40px;
                          }
                        }
                        a {
                          color: #6e6b7b;
                          font-size: 0.85rem;
                          font-weight: 500;
                          @include hover {
                            color: $color-primary;
                          }
                        }
                        p {
                          font-size: 0.857rem;
                          font-weight: 400;
                          color: #b9b9c3;
                        }
                      }
                      .input {
                        width: 100px;
                        display: grid;
                        grid-template-columns: 30px 1fr 30px;
                        height: 30px;
                        border: 1px solid #d8d6de;
                        border-radius: 0.357rem;
                        background: $color-white;
                        div {
                          @include full-size;
                          @include flex-center;
                          font-size: 16px;
                          color: #b9b9c3;
                          cursor: pointer;
                        }
                        input {
                          @include full-size;
                          border-left: 1px solid #ebe9f1;
                          border-right: 1px solid #ebe9f1;
                          color: #b9b9c3;
                          font-size: 14px;
                          text-align: center;
                          font-weight: 300;
                        }
                      }
                      .price {
                        color: #5e5873;
                        font-weight: 600;
                        font-size: 13px;
                        text-align: center;
                        span {
                          font-size: 11px;
                          margin-left: -2px;
                        }
                      }
                    }
                  }
                }
                .footer {
                  border-top: 1px solid #ebe9f1;
                  padding: 1.28rem;
                  .row {
                    @include flex-between;
                    font-size: 14px;
                    margin-bottom: 1rem;
                    cursor: auto;
                    span {
                      font-weight: bold;
                      color: $color-primary;
                    }
                  }
                  .links {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    grid-template-rows: 38px;
                    grid-gap: 20px;
                    a {
                      @include full-size;
                      @include flex-center;
                      border-radius: 0.358rem;
                      background: $color-white;
                      border-color: $color-primary;
                      color: $color-primary;
                      font-weight: 400;
                      font-size: 0.8rem;
                      border: 1px solid $color-primary;
                      @include hover {
                        background: $color-primary;
                        color: $color-white;
                        border-color: transparent;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
