.table-recommendedPrices {
  .header,
  .table_row {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 1fr 1fr 1fr 70px 100px;
    @include media-max(800px) {
      grid-template-columns: 1fr 1fr 70px;
    }
    @include media-max(650px) {
      grid-template-columns: 1fr 70px;
    }
  }
  .header {
    @include media-max(800px) {
      padding-left: 45px !important;
      .table_cell {
        &:first-child {
          display: flex !important;
        }
        &:nth-child(2) {
          display: flex !important;
          @include media-max(650px) {
            display: none !important;
          }
        }
        &:nth-child(3) {
          display: none !important;
        }
        &:nth-child(4) {
          display: none !important;
        }
        &:nth-child(5) {
          display: flex !important;
        }
        display: none;
      }
    }
  }
  .table_row {
    @include media-max(800px) {
      @include flex-align-center;
      flex-wrap: wrap;
      height: unset !important;
      padding: 15px 10px !important;
      .expand_button {
        display: block;
      }
      .table_cell {
        &:nth-child(2) {
          margin-left: 10px;
          display: flex !important;
          flex: 1;
          a {
            width: 100% !important;
          }
        }
        &:nth-child(3) {
          display: flex !important;
          flex: 1;
          @include media-max(650px) {
            display: none !important;
          }
        }
        &:nth-child(4) {
          display: none;
        }
        &:nth-child(5) {
          display: none;
        }
        &:nth-child(6) {
          display: flex !important;
          width: 70px !important;
        }
        display: none;
      }
    }
  }
  .mobile_table_wrapper {
    @include media-max(800px) {
      display: block;
      .mobile_table_holder .mobile_table_row .row_label {
        min-width: 70px;
      }
      .mobile_table_row:nth-child(1) {
        display: none;

        @include media-max(650px) {
          display: grid;
        }
      }
    }
  }
}
.table-conditions {
  .header,
  .table_row {
    display: grid;
    align-items: center;
    grid-template-columns: 100px 100px 1fr 1fr 70px 100px;
    @include media-max(800px) {
      grid-template-columns: 1fr 1fr 70px;
    }
    @include media-max(650px) {
      grid-template-columns: 1fr 70px;
    }
  }
  .header {
    @include media-max(800px) {
      padding-left: 45px !important;
      .table_cell {
        &:first-child {
          display: flex !important;
        }
        &:nth-child(2) {
          display: flex !important;
          @include media-max(650px) {
            display: none !important;
          }
        }
        &:nth-child(3) {
          display: none !important;
        }
        &:nth-child(4) {
          display: none !important;
        }
        &:nth-child(5) {
          display: flex !important;
        }
        display: none;
      }
    }
  }
  .table_row {
    @include media-max(800px) {
      @include flex-align-center;
      flex-wrap: wrap;
      height: unset !important;
      padding: 15px 10px !important;
      .expand_button {
        display: block;
      }
      .table_cell {
        &:nth-child(2) {
          margin-left: 10px;
          display: flex !important;
          flex: 1;
          a {
            width: 100% !important;
          }
        }
        &:nth-child(3) {
          display: flex !important;
          flex: 1;
          @include media-max(650px) {
            display: none !important;
          }
        }
        &:nth-child(4) {
          display: none;
        }
        &:nth-child(5) {
          display: none;
        }
        &:nth-child(6) {
          display: flex !important;
          width: 70px !important;
        }
        display: none;
      }
    }
  }
  .mobile_table_wrapper {
    @include media-max(800px) {
      display: block;
      .mobile_table_holder .mobile_table_row .row_label {
        min-width: 70px;
      }
      .mobile_table_row:nth-child(1) {
        display: none;

        @include media-max(650px) {
          display: grid;
        }
      }
    }
  }
}
