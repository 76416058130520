.m-0 {
  margin: 0 !important;
}

.mb_0 {
  margin-bottom: 0 !important;
}

.m-7 {
  margin: 0 7px !important;
}

.mt_10 {
  margin-top: 10px;
}

.bg_primary {
  background: $color-primary !important;
  color: $color-white !important;
}

.mr-7 {
  margin-right: 7px !important;
}

.mt-0 {
  margin-top: 15px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.mb-50 {
  margin-bottom: 50px;
}

.ml_20 {
  margin-left: 20px !important;
}

.ml_8 {
  margin-left: 8px;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.ml_10 {
  margin-left: 10px !important;
}

.align_row {
  @include flex-align-center;
}

.cursor {
  cursor: pointer;
  padding: 15px 0;
}

.pointer {
  cursor: pointer;
}

h1.page_root_title {
  font-size: 2rem;
  font-weight: 400;
  color: #333e48;
  text-align: center;
  @include media-max(768px) {
    font-size: 1.5em;
  }
}

.space_between {
  justify-content: space-between;
}

.flex_align {
  @include flex-align-center;
}

.jc_end {
  @include flex-align-center;
  justify-content: flex-end;
}

.mt-0px {
  margin-top: 0 !important;
}

.img_placeholder {
  opacity: 0.3;
  width: 80px;
}

.custom_m {
  margin: 40px 0;
  align-items: start !important;
}

.editorClassName {
  z-index: 1;
  position: relative;
  font-size: 13px;
  border-radius: 5px;
  padding: 0 20px;
  background: transparent;
  color: $color-text;
  transition: all 0.2s ease;
  width: 100%;
  min-height: 200px !important;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.dangerouslySetInnerHTML {
  ol,
  li {
    list-style-type: revert;
  }
  font-size: 14px !important;
  display: flex !important;
  flex-direction: column !important;
  p {
    font-size: 14px !important;
  }
  a {
    color: $color-primary !important;
    font-size: 14px !important;
    margin: 0 2px !important;
  }
}

.dangerouslySetInnerHTMLNews {
  font-size: 14px !important;
  display: flex !important;
  p {
    font-size: 14px !important;
  }
  a {
    color: $color-primary !important;
    font-size: 14px !important;
    margin: 0 2px !important;
  }
}

.change_device_dropdown_outer {
  background: #fff;
  width: 250px;
  height: 40px;
  position: relative;
  border-radius: 5px;
  color: rgba(0, 0, 0, 0.4);
  transition: all 0.2s ease;
  border: 1px solid rgba(0, 0, 0, 0.2);
  .change_device_dropdown_inner {
    @include full-size;
    .root {
      padding: 0 10px;
      @include full-size;
      @include flex-between;
      cursor: pointer;
      font-size: 14px;
      color: $color-text;
    }
    .dropdown_holder {
      transition: all 0.2s ease;
      z-index: 2;
      position: absolute;
      top: calc(100% + 5px);
      left: -1px;
      right: -1px;
      border-radius: 5px;
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-top: 0;
      background: #fff;
      padding: 5px 0;
      .dropdown_content {
        @include full-size;
        p {
          font-size: 14px;
          padding: 5px 15px;
          cursor: pointer;
          @include hover {
            background: $color-grey-10;
          }
          &.active {
            color: $color-text;
            background: $color-grey-10;
          }
        }
      }
    }
  }
}

.expand_more_arrow {
  color: #b9b9b9;
  transition: all 0.2s ease !important;
  &.active {
    transform: rotate(180deg);
  }
}

.category_dropzone {
  width: 200px !important;
  height: 200px !important;
  .input_file_holder {
    @include inline-bg;
    width: 100% !important;
    height: 100% !important;
    .text {
      font-size: 16px !important;
    }
    .delete_icon {
      position: absolute;
      top: 5px;
      right: 5px;
      color: $color-danger;
      z-index: 2;
    }
  }
}

.root_loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba($color: $color-white, $alpha: 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}

.color_black {
  color: $color-text !important;
}

.active_index {
  color: $color-text;
  background: rgb(204, 204, 204);
}

.mass_update_discounts_modal {
  .MuiDialogContent-root {
    padding: 0;
  }
  .MuiDialog-paper {
    padding: 30px;
    width: 500px;
    overflow: unset;
    @include media-max(600px) {
      margin: 20px;
      padding: 20px;
      width: 100%;
    }
  }
  .MuiDialogContent-root {
    overflow: unset;
  }
  .MuiDialogTitle-root {
    padding: 0;
    h2 {
      margin-bottom: 40px;
      text-align: center;
    }
  }
  .product_brand_field {
    margin-top: 30px;
    .input_holder {
      margin: 0;
    }
  }
  .input_holder {
    margin-top: 30px;
  }
  .form_buttons_group {
    margin-top: 30px;
  }
}
