.cms_page {
  &.actions_page {
    .dropzones {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 30px;
      margin: 20px 0;
      @include media-max(900px) {
        grid-template-columns: 100%;
        grid-gap: 20px;
      }
    }
    .dropzone_wrapper {
      width: 100%;
      .input_file_holder {
        height: 150px;
        border: 1px dashed rgba($color: $color-black, $alpha: 0.4);
        position: relative;
        cursor: pointer;
        input {
          @include absolute-full;
          opacity: 0;
          z-index: 1;
          cursor: pointer;
        }
        span {
          @include absolute-full;
          @include flex-center;
          font-size: 18px;
          cursor: pointer;
          color: rgba($color: $color-black, $alpha: 0.4);
        }
        .photo {
          @include inline-bg;
          svg {
            position: absolute;
            top: 10px;
            right: 10px;
            color: $color-danger;
            font-size: 20px;
            z-index: 1;
          }
        }
      }
    }
    .box_holder {
      padding: 40px 20px 30px 20px;
      .filter_dropdowns {
        display: grid;
        grid-template-columns: 300px 300px 100px;
        grid-gap: 20px;
        @include media-max(900px) {
          grid-template-columns: 100%;
          grid-gap: 30px;
        }
      }
      form {
        margin-top: 50px;
      }
      .update_action_form {
        .form_row {
          display: grid;
          grid-template-columns: 1fr auto;
          grid-gap: 20px;
        }
      }
    }
  }
}
