.scroll_to_top {
  position: fixed;
  bottom: 20px;
  right: 30px;
  width: 45px;
  height: 45px;
  z-index: 10;
  @include flex-center;
  background: #888;
  color: $color-white;
  border-radius: 50%;
  cursor: pointer;
  opacity: 0.5;
  @include hover {
    opacity: 1;
  }
  svg {
    width: 40px;
    height: 40px;
  }
}

@keyframes scaleanime {
  from {
    top: -5px;
    right: -5px;
    bottom: -5px;
    left: -5px;
  }
  to {
    top: -10px;
    right: -10px;
    bottom: -10px;
    left: -10px;
  }
}
