.actions_page {
  .banner_img {
    width: 100%;
  }
  .actions_cards {
    margin: 50px 0;
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(4, 1fr);
    @include media-max(1200px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @include media-max(768px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @include media-max(500px) {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 20px 0;
    }
    .card {
      width: 100%;
      padding: 20px;
      @include border(1, #ddd);
      cursor: pointer;
      img {
        width: 100%;
      }
      h3 {
        margin-top: 20px;
        color: $color-primary;
        font-weight: 600;
        font-size: 1rem;
        @include media-max(500px) {
          font-size: 14px;
        }
      }
    }
  }
  .products_grid_wrapper {
    margin: 50px 0;
  }
}
