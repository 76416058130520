.table-activityLog {
  .header,
  .table_row {
    display: grid;
    align-items: center;
    grid-template-columns: 80px 300px 1fr 160px;
    @include media-max(900px) {
      grid-template-columns: 80px 1fr 1fr 160px;
    }
    @include media-max(800px) {
      grid-template-columns: 75px 150px 1fr;
    }
    @include media-max(650px) {
      grid-template-columns: 75px 1fr;
    }
  }
  .header {
    @include media-max(800px) {
      padding-left: 40px !important;
      .table_cell {
        &:nth-child(1) {
          display: flex !important;
        }
        &:nth-child(2) {
          display: flex !important;
        }
        &:nth-child(3) {
          display: flex !important;
          @include media-max(650px) {
            display: none !important;
          }
        }
        display: none;
      }
    }
  }
  .table_row {
    cursor: pointer;
    @include media-max(800px) {
      @include flex-align-center;
      flex-wrap: wrap;
      height: unset !important;
      padding: 8px 10px !important;
      .expand_button {
        display: block;
      }
      .table_cell {
        &:nth-child(2) {
          display: flex !important;
          width: 40px !important;
          margin: 0 20px !important;
        }
        &:nth-child(3) {
          width: 150px !important;
          display: flex !important;
        }
        &:nth-child(4) {
          width: 250px !important;
          display: flex !important;
          @include media-max(650px) {
            display: none !important;
          }
        }
        display: none;
      }
    }
    .mobile_table_wrapper {
      @include media-max(800px) {
        display: block;
        .mobile_table_holder .mobile_table_row .row_label {
          min-width: 70px;
        }
        .mobile_table_row:nth-child(1) {
          display: none;
          @include media-max(650px) {
            display: grid;
          }
        }
      }
    }
    .table_cell {
      overflow: unset !important;
    }
  }
}

.activity_log_preview_modal {
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  @include fixed-full;
  @include flex-center;
  .content_holder {
    width: 750px;
    max-height: 90%;
    background: $color-white;
    position: relative;
    overflow-y: scroll;
    border-radius: 0.5em;
    padding: 40px 20px 20px 20px;
    @include media-max(900px) {
      width: 90%;
    }
    @include media-max(600px) {
      width: 95%;
      padding: 40px 10px 10px 10px;
    }
    &::-webkit-scrollbar {
      width: 0;
    }
    .close {
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
      color: #8a8d90;
    }
    .item {
      display: grid;
      align-items: center;
      grid-template-columns: 170px 1fr;
      grid-gap: 0 20px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.08);
      color: #8a8d90;
      font-size: 12px;
      min-height: 45px;
      @include media-max(600px) {
        grid-template-columns: 140px 1fr;
        grid-gap: 0 10px;
      }
      @include media-max(400px) {
        grid-template-columns: 120px 1fr;
      }
      .row {
        @include flex-align-center;
      }
      @include media-max(900px) {
        padding: 0 10px;
      }
      &.b_0 {
        span {
          border-right: 0;
        }
      }
      &:last-child {
        border-bottom: 0;
      }
      .avatar {
        @include inline-bg;
        width: 30px;
        height: 30px;
        margin-right: 10px;
        border-radius: 8px;
      }
      a,
      .link {
        cursor: pointer;
        color: $color-purple;
        font-size: 12px;
        font-style: italic;
        @include media-max(600px) {
          font-size: 11px;
        }
        @include media-max(350px) {
          font-size: 10px;
        }
      }
      span {
        @include flex-align-center;
        text-transform: uppercase;
        width: 100%;
        height: 100%;
        border-right: 1px solid rgba(0, 0, 0, 0.08);
        font-size: 12px;
        font-weight: 600;
        @include media-max(600px) {
          font-size: 11px;
        }
        @include media-max(350px) {
          font-size: 10px;
        }
      }
      .right {
        width: 100%;
        padding: 5px 0;
        .skeleton_inner {
          width: 100%;
        }
      }
      .skeleton_inner {
        margin: 0;
        width: 100%;
        .skeleton_wrapper {
          width: 40%;
          padding: 0;
        }
      }
    }
  }
}

.activity_log_dropdown_wrap {
  width: 250px;
  height: 40px;
  border: 1px solid #dae1e7;
  border-radius: 6px;
  position: relative;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.4);
  @include media-max(500px) {
    width: calc(100% - 20px);
  }
  .dropdown_holder {
    @include full-width;
    @include flex-between;
    padding: 0 20px;
    cursor: pointer;
    .active {
      color: $color-text;
    }
    span {
      width: 20px;
      height: 20px;
      @include flex-center;
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
  ul.dropdown_items {
    position: absolute;
    left: 0;
    right: 0;
    top: 110%;
    background: $color-white;
    z-index: 2;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
    li {
      padding: 8px 20px;
      color: rgba(0, 0, 0, 0.4);
      cursor: pointer;
      font-size: 13px;
      background: $color-white;
      @include hover {
        background: $color-grey-10;
      }
      &.active {
        background: $color-grey-10;
        color: $color-text;
      }
    }
  }
}

.activty_log {
  .table_helper {
    .align_row:nth-child(1) {
      @include media-max(850px) {
        width: 100%;
        flex-direction: column;
        align-items: start;
        .paginate {
          margin-bottom: 20px;
        }
      }
      .filters {
        display: grid;
        grid-template-columns: repeat(2, 250px);
        grid-gap: 0 20px;
        @include media-max(850px) {
          width: 100%;
          grid-template-columns: repeat(2, 1fr);
        }
        @include media-max(700px) {
          grid-template-columns: 100%;
          grid-gap: 20px 0;
        }
        .activity_log_dropdown_wrap {
          width: 100%;
        }
        .search_select_holder {
          border: 1px solid #dae1e7;
          border-radius: 6px;
          position: relative;
          font-size: 13px;
          color: rgba(0, 0, 0, 0.4);
          .root span.icon {
            font-size: 13px;
          }
        }
      }
    }
    .align_row:nth-child(2) {
      display: none;
    }
  }
}
