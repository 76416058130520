.control_table_page_wrap {
  margin: 50px 0;
  // display: grid;
  grid-template-columns: 320px 1fr;
  grid-gap: 0 50px;
  @include media-max(1200px) {
    grid-template-columns: 100%;
    grid-gap: 50px 0;
  }
  nav {
    border-top: 1px solid #eceeef;
    p,
    a {
      cursor: pointer;
      border-bottom: 1px solid #eceeef;
      @include flex-between;
      color: $color-text;
      padding: 0.857em 10px;
      font-size: 15px;
      @include transition;
      @include hover {
        background: $color-grey-10;
      }
      svg {
        opacity: 0.5;
        font-size: 18px;
      }
      &.active {
        font-weight: 600;
        svg {
          opacity: 1;
        }
      }
    }
  }
  .page_content {
    width: 100%;
    &.account_details {
      form {
        &:first-child {
          margin-bottom: 50px;
        }
      }
      .input_holder {
        margin-bottom: 30px;
        input:focus {
          border-color: $color-primary;
        }
        input:focus + label {
          color: $color-primary;
        }
      }
      button {
        width: 170px;
        height: 40px;
        font-size: 14px;
      }
    }
    &.addresses {
      h6 {
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 20px;
      }
      .grid {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 30px;
        @include media-max(1000px) {
          grid-template-columns: 100%;
          grid-gap: 40px;
        }
        .item {
          width: 100%;
          .header {
            width: 100%;
            @include flex-between;
            font-size: 14px;
            padding-bottom: 10px;
            margin-bottom: 30px;
            border-bottom: 1px solid #dadada;
            position: relative;
            @include media-max(1000px) {
              margin-bottom: 20px;
            }
            &::after {
              content: '';
              position: absolute;
              bottom: -1px;
              height: 2px;
              width: 150px;
              left: 0;
              background: $color-primary;
            }
            h3 {
              font-size: 24px;
              font-weight: 500;
              @include media-max(700px) {
                font-size: 17px;
              }
            }
            a {
              font-size: 14px;
              @include media-max(700px) {
                font-size: 12px;
              }
            }
            .right {
              @include flex-align-center;
              .delete_link {
                margin-right: 10px;
                cursor: pointer;
                color: $color-danger;
                font-size: 14px;
                @include media-max(700px) {
                  font-size: 12px;
                }
              }
            }
          }
          .empty {
            font-size: 15px;
            @include media-max(700px) {
              font-size: 12px;
            }
          }
          .body {
            .row {
              display: grid;
              grid-template-columns: 120px 1fr;
              align-items: center;
              font-size: 14px;
              padding: 10px 0;
              @include media-max(700px) {
                font-size: 12px;
              }
            }
          }
        }
      }
    }
    &.addresses_form {
      h2 {
        font-size: 26px;
        font-weight: 500;
        padding-bottom: 10px;
        margin-bottom: 30px;
        border-bottom: 1px solid #dadada;
        position: relative;
        &::after {
          content: '';
          position: absolute;
          bottom: -1px;
          height: 2px;
          width: 150px;
          left: 0;
          background: $color-primary;
        }
      }
      form {
        .form_holder {
          display: grid;
          width: 100%;
          grid-template-columns: repeat(2, 1fr);
          grid-gap: 30px;
          margin: 50px 0 20px 0;
          @include media-max(700px) {
            grid-template-columns: 100%;
          }
          .input_holder {
            width: 100%;
            input:focus {
              border-color: $color-primary;
            }
            input:focus + label {
              color: $color-primary;
            }
          }
        }
        button {
          width: 170px;
          height: 40px;
          font-size: 14px;
        }
      }
    }
    &.orders {
      .table {
        .header,
        .item {
          width: 100%;
          display: grid;
          align-items: center;
          font-size: 14px;
          height: 50px;
          grid-template-columns: 200px 200px 200px 1fr 200px 100px;
          @include media-max(1480px) {
            grid-template-columns: 150px 150px 150px 1fr 90px;
            font-size: 12px;
          }
          @include media-max(992px) {
            grid-template-columns: 100px 120px 100px 1fr 90px;
          }
          @include media-max(900px) {
            grid-template-columns: 100%;
            height: auto;
            margin-bottom: 20px;
          }
        }
        .header {
          border-bottom: 2px solid #dadada;
          @include media-max(900px) {
            display: none;
          }
        }
        .item {
          border-bottom: 1px solid #dadada;
          a {
            width: 100%;
            height: 35px;
            border-radius: 6px;
            background: rgb(168, 168, 168);
            color: rgb(51, 51, 51);
            @include flex-center;
            font-size: 14px;
            @include media-max(1480px) {
              font-size: 12px;
            }
            svg {
              width: 18px;
              height: 18px;
              font-size: 18px;
              margin-left: 5px;
              @include media-max(1480px) {
                width: 16px;
                height: 16px;
                font-size: 16px;
              }
            }
            @include hover {
              opacity: 0.8;
            }
          }
          .cell {
            &.download {
              width: 60%;
            }
            &.last {
              grid-column-end: -1;
            }
            span {
              display: none;
            }
            @include media-max(900px) {
              width: 100%;
              display: grid;
              align-items: center;
              grid-template-columns: 130px 1fr;
              font-size: 14px;
              margin-bottom: 20px;
              @include media-max(500px) {
                font-size: 12px;
              }
              span {
                display: block;
              }
              a {
                width: 90px;
              }
            }
          }
        }
      }
    }
    &.show_order {
      h4 {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 50px;
        @include media-max(1000px) {
          font-size: 14px;
          line-height: 33px;
        }
        @include media-max(500px) {
          font-size: 12px;
          line-height: 28px;
        }
        span {
          background: rgba(247, 208, 134, 0.438);
          border-radius: 4px;
          padding: 5px;
          @include media-max(500px) {
            padding: 3px;
          }
        }
      }
      h3 {
        font-size: 24px;
        font-weight: 500;
        border-bottom: 1px solid #dadada;
        padding-bottom: 10px;
        margin-bottom: 20px;
        @include media-max(1000px) {
          font-size: 20px;
        }
      }
      .table {
        margin-bottom: 50px;
        .header,
        .item {
          border-bottom: 1px solid #dadada;
          font-size: 14px;
          display: grid;
          align-items: center;
          grid-template-columns: repeat(2, 1fr);
          padding: 15px 0;
          @include media-max(1000px) {
            font-size: 12px;
          }
        }
        .header {
          font-weight: bold;
        }
      }
      .details {
        margin-bottom: 50px;
        .row {
          padding: 5px 0;
          display: grid;
          align-items: center;
          grid-template-columns: 150px 1fr;
          @include media-max(1000px) {
            font-size: 12px;
          }
        }
      }
      .email {
        font-size: 16px;
        @include flex-align-center;
        @include media-max(1000px) {
          font-size: 12px;
        }
        @include media-max(500px) {
          flex-direction: column;
        }
        a {
          font-size: 16px;
          margin-left: 5px;
          @include media-max(1000px) {
            font-size: 12px;
          }
          @include media-max(500px) {
            margin-top: 5px;
          }
        }
      }
    }
  }
}
