.webCredit_page {
  //font-family: poppins;
  p.italic {
    font-style: italic;
  }
  .heading {
    text-align: center;
    margin-top: 3rem;

    h1 {
      font-size: 42px;
      margin-bottom: 1rem;
      @include media-max(768px) {
        font-size: 26px;
      }
    }
    p {
      font-size: 18px;
      @include media-max(768px) {
        font-size: 16px;
        br {
          display: none;
        }
      }
    }
  }
  .bullets {
    display: flex;
    gap: 4rem;
    margin-top: 3rem;
    @include media-max(768px) {
      flex-wrap: wrap;
      gap: 1rem;
    }
    .bullet {
      width: 30%;
      padding: 2rem;
      text-align: center;
      background-color: #ffdd00;
      border-radius: 0.5rem;
      @include media-max(768px) {
        width: 100%;
        min-height: 350px;
      }
      svg {
        height: 70px;
        width: 70px;
      }
      h2 {
        font-size: 22px;
      }
      p {
        font-size: 14px;
        margin-top: 1rem;
        &.italic {
          font-style: italic;
        }
      }
    }
  }
  .benefitsWrapper {
    text-align: center;
    margin-top: 5rem;
    @include media-max(768px) {
      margin-top: 3rem;
    }
    h2 {
      font-size: 32px;
      font-weight: 600;
      @include media-max(768px) {
        font-size: 26px;
      }
    }
    .benefits {
      display: flex;
      background-color: #f2f4f5;
      justify-content: center;
      margin-top: 2rem;
      gap: 2rem;
      border-radius: 0.5rem;
      @include media-max(768px) {
        flex-wrap: wrap;
        gap: 1rem;
      }

      .benefit {
        padding: 2rem;
        width: 30%;
        @include media-max(768px) {
          width: 100%;
        }
        svg {
          height: 70px;
          width: 70px;
        }
        p {
          margin-top: 1rem;
          font-size: 14px;
        }
      }
    }
    .alreadyClient {
      background-color: #ffdd00;
      padding: 2rem;
      margin-top: 1rem;
      border-radius: 0.5rem;
    }
  }
  .applyWrapper {
    text-align: center;
    margin-top: 5rem;
    @include media-max(768px) {
      margin-top: 3rem;
    }
    h2 {
      font-size: 32px;
      @include media-max(768px) {
        font-size: 26px;
      }
    }

    .items {
      margin-top: 5rem;

      display: flex;
      flex-direction: column;
      gap: 1rem;
      @include media-max(768px) {
        gap: 0;
        border-radius: 0.5rem;
        overflow: hidden;
        margin-top: 3rem;
      }
      .item {
        display: flex;

        text-align: left;
        align-items: center;
        background-color: #ffdd00;
        border-radius: 0.5rem;
        @include media-max(768px) {
          border-radius: 0;
          display: block;
          padding: 1rem;
          padding-bottom: 2rem;
        }
        .svg {
          padding: 1rem 2rem;
          @include media-max(768px) {
            padding: 0;
            padding-bottom: 1rem;
          }
          svg {
            height: 90px;
            width: 90px;
          }
        }
        .right {
          padding-right: 15rem;
          @include media-max(768px) {
            padding-right: 0;
          }
          h3 {
            margin-bottom: 0.5rem;
          }
          p {
            font-size: 14px;
          }

          li {
            list-style-type: disc;
            margin-left: 1.5rem;
          }
        }
      }
    }
  }
  .bottom {
    display: flex;
    margin: 5rem 0;
    gap: 3rem;
    @include media-max(768px) {
      flex-wrap: wrap;
    }
    .left {
      text-align: center;
      width: 50%;
      @include media-max(768px) {
        width: 100%;
      }
      h2 {
        font-size: 32px;
      }
      .exampleWrapper {
        background-color: #f2f4f5;
        border-radius: 0.5rem;
        padding: 2rem 3rem;
        margin-top: 3rem;
        position: relative;
        //min-height: 410px;
        @include media-max(768px) {
          padding: 1rem;
        }
        p {
          font-size: 23px;
          margin-bottom: 1rem;
          @include media-max(768px) {
            font-size: 16px;
          }
        }
        .example {
          display: flex;
          text-align: left;
          .leftSide p {
            min-width: 20rem;
            @include media-max(768px) {
              min-width: 10rem;
            }
          }
        }
        .center {
          @include media-max(768px) {
            padding: 0 2rem;
          }
        }
      }
    }
    .right {
      text-align: center;
      width: 50%;
      @include media-max(768px) {
        width: 100%;
      }
      h2 {
        font-size: 32px;
      }
      .video {
        margin-top: 3rem;
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 56.25%; /* 16:9 aspect ratio */
      }

      .video-iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}
