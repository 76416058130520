.auth_form {
  margin-bottom: 100px;
  .auth_form_holder {
    @include flex-column-center;
    width: 600px;
    margin: 0 auto;
    @include media-max(750px) {
      width: 100%;
    }
    .title {
      display: inline-flex;
      padding: 0 50px 10px 0;
      border-bottom: 1px solid $color-grey-07;
      position: relative;
      @include media-max(750px) {
        width: 100%;
      }
      &::after {
        content: '';
        position: absolute;
        bottom: -1px;
        left: 0;
        width: 35%;
        height: 2px;
        background: $color-primary;
      }
      h1 {
        color: $color-text;
        font-size: 1.786em;
        font-weight: 500;
      }
    }
    h3.subtitle {
      margin: 30px 0;
      font-weight: 400;
      color: $color-text;
      font-size: $font-size-regular;
      @include media-max(750px) {
        width: 100%;
      }
    }
    form {
      width: 100%;
      @include flex-column;
      @include media-max(750px) {
        margin-top: 30px;
      }
      .form_field {
        margin-bottom: 30px;
        input {
          font-size: 14px;
          border-radius: 6px;
          &:focus {
            border-color: $color-primary;
            & + label {
              color: $color-primary;
            }
          }
        }
      }
      .error_msg {
        color: $color-danger;
        font-weight: 700;
        font-size: $font-size-regular;
        margin: -20px 0 30px 0;
      }
      p.description {
        color: $color-text;
        font-size: $font-size-regular;
        margin-bottom: 20px;
        a {
          font-size: 1em;
        }
      }
      .row {
        width: 100%;
        @include flex-between;
        button {
          margin: 0 10px 0 0;
        }
        a {
          @include hover {
            text-decoration: underline;
            text-align: center;
          }
          @include media-max(400px) {
            font-size: 12px;
          }
        }
      }
      button {
        font-size: $font-size-regular;
        background: $color-primary;
        color: $color-white;
        font-weight: 700;
        width: 150px;
        height: 40px;
        border-radius: 6px;
        margin: 0 auto;
        @include hover {
          background: $color-black;
        }
      }
    }
  }
  .span_or {
    margin: 30px 0;
    color: rgb(131, 131, 131);
  }
  .facebook_button {
    background-color: #1877f2;
    display: flex;
    justify-content: center;
    justify-items: center;
  }
  .social_auth_buttons {
    @include flex-align-center;
    @include media-max(600px) {
      flex-direction: column;
    }
    button {
      border-radius: 6px;
      font-size: 18px;
      color: $color-white;
      font-weight: 400;
      margin: 0 20px;
      display: grid;
      align-items: center;
      grid-template-columns: 50px 150px;
      grid-template-rows: 45px;
      @include media-max(600px) {
        margin: 0 0 20px 0;
        grid-template-rows: 40px;
        font-size: 14px;
      }
      @include hover {
        opacity: 0.8;
      }
      span {
        @include flex-center;
        @include full-size;
        svg {
          font-size: 24px;
          @include media-max(600px) {
            font-size: 20px;
          }
        }
      }
      &.facebook_btn {
        background: #3c66c4;
      }
      &.google_btn {
        background: #cf4332;
      }
      p {
        @include full-size;
        @include flex-center;
        padding: 0 10px;
        border-left: 1px solid $color-white;
      }
    }
  }
}
