.privacy_policy {
  .group {
    margin: 50px 0;
    h1 {
      font-weight: 400;
      font-size: 1.7rem;
      margin-bottom: 1rem;
      color: rgba($color: #000000, $alpha: 0.7);
      @include media-max(768px) {
        font-size: 1.4rem;
      }
    }
    h3 {
      font-weight: 400;
      font-size: 1.7rem;
      margin-bottom: 1rem;
      color: rgba($color: #000000, $alpha: 0.7);
      @include media-max(768px) {
        font-size: 1.4rem;
      }
    }
    p {
      margin-bottom: 1rem;
      color: rgba($color: #000000, $alpha: 0.7);
      line-height: 1.714em;
      font-size: 0.875em;
      letter-spacing: -0.01em;
    }

    ul {
      margin-bottom: 1rem;
      margin-left: 20px;
      li {
        list-style-type: disc;
        color: rgba($color: #000000, $alpha: 0.7);
        line-height: 1.714em;
        font-size: 0.875em;
        letter-spacing: -0.01em;
      }
    }
  }
}
