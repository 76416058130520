.preview_news_page {
  .helpers {
    padding-left: 10%;
    padding-right: 10%;
    h3 {
      font-weight: 400;
      font-size: 1.3rem;
      color: $color-text;
      position: relative;
      padding-bottom: 10px;
      margin-bottom: 20px;
      border-bottom: 1px solid #dadada;
      &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100px;
        height: 2px;
        background: $color-primary;
      }
    }
    .latest_news {
      margin-bottom: 50px;
      display: flex;
      justify-content: space-evenly;
      @include media-max(700px) {
        flex-direction: column;
      }

      .item {
        width: 20%;
        display: grid;
        grid-template-columns: 100px 1fr;

        @include media-max(700px) {
          width: 100%;
          display: grid;
          grid-template-columns: 100px 1fr;
        }
        margin-bottom: 20px;
        .text {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          h4 {
            font-size: 14px;
            color: $color-text;
            font-weight: 400;
          }
          p {
            color: #878787;
            font-size: 12px;
          }
        }
      }
    }
    .mini_banner {
      display: block;
      cursor: pointer;
      @include media-max(1200px) {
        width: 250px;
      }
    }
  }
  .title {
    display: flex;
    justify-content: center;
    @include flex-between;
    @include media-max(700px) {
      @include flex-column-center;
      text-align: center;
    }
    h2 {
      width: 100%;
      font-weight: 400;
      font-size: 1.3rem;
      color: $color-text;
      @include media-max(700px) {
        font-size: 1rem;
      }
    }
    p {
      color: #878787;
      font-size: 12px;
      @include flex-align-center;
      @include media-max(700px) {
        margin: 10px 0 20px 0;
      }
      svg {
        margin-right: 3px;
      }
    }
  }
  margin-bottom: 50px;
  img {
    width: 50%;
  }
  .wrapper {
    width: 100%;
    display: flex;
    color: #878787;
    margin-top: 2%;

    .content {
      width: 100%;
      display: flex;
      @include media-max(700px) {
        flex-direction: column;
      }
      .main_photo {
        text-align: center;
        margin-bottom: 20px;
        img {
          width: 90%;
        }
      }

      .texts {
        width: 50%;
        @include media-max(700px) {
          width: 100%;
          padding-right: 5%;
        }
        margin-left: 5%;
        a,
        p {
          margin-bottom: 1rem;
          font-size: 16px;
          line-height: 2;
          @include media-max(700px) {
            font-size: 14px;
            line-height: 1.8;
          }
        }
        .row {
          margin: 10px 0 20px 0;
          display: flex;
          padding-bottom: 20px;
          border-bottom: 1px solid #ececec;
          p,
          a {
            color: #878787;
            font-size: 12px;
            margin-left: 1%;
          }
          span {
            display: block;
            width: 5px;
            height: 5px;
            border-radius: $radius-round;
            background: #878787;
            margin: 0 10px;
          }
        }
      }
    }
  }
}
