.payments_methods_page {
  .grid_content {
    display: grid;
    grid-template-columns: 140px 1fr;
    grid-gap: 0 20px;
    margin: 50px 0;
    @include media-max(992px) {
      grid-template-columns: 100%;
      margin: 30px 0 0 0;
      grid-gap: 0;
    }
    nav {
      a {
        width: 100%;
        padding: 15px 10px;
        background: $color-primary;
        color: $color-white;
        font-size: 14px;
        margin-bottom: 20px;
        text-align: center;
        @include flex-center;
        cursor: pointer;
        @include border(1, transparent);
        @include transition;
        &.active {
          @include border(1, #f0f0f0);
          background-color: #f8f8f8;
          color: #666;
        }
      }
    }
    .box {
      @include border(1, #f2f2f2);
      padding: 30px;
      @import './credits';
      @include media-max(768px) {
        padding: 20px;
      }
      h3 {
        text-transform: uppercase;
        color: $color-primary;
        margin-bottom: 1rem;
        font-size: 1.5rem;
        font-weight: 400;
      }
      h4 {
        margin-bottom: 1rem;
        font-size: 1.1rem;
        font-weight: 600;
      }
      p {
        margin-bottom: 1rem;
      }
      .discounted_cash_page {
        img {
          width: 100%;
          margin: 30px 0;
        }
        h6 {
          color: #9fc95a;
          font-size: 1em;
          font-weight: 600;
          margin-bottom: 1em;
        }
        .links {
          @include flex-column;
        }
      }
      .interes_free_account {
        h3 {
          margin-bottom: 50px;
        }
        img {
          width: 100%;
          margin: 30px 0;
        }
        h6 {
          color: #9fc95a;
          font-size: 1em;
          font-weight: 600;
          margin-top: 1em;
        }
      }
      .pay_cards {
        img {
          width: 100%;
          margin: 30px 0;
        }
        h4 {
          margin-bottom: 1em;
          font-weight: 600;
          font-size: 18px;
        }
      }
      .refund_pdv {
        img {
          width: 100%;
          margin: 30px 0;
        }
        p.italic {
          font-style: italic;
          word-break: break-all;
          a {
            margin-left: 3px;
          }
        }
      }
    }
  }
}
