.products_sections_inner {
  .container {
    position: relative;
  }
  .swiper-container {
    position: unset;
  }
  .swiper-pagination-bullets {
    bottom: -30px !important;
    @include media-min(1000px) {
      right: 20px !important;
      top: 5px !important;
      left: unset !important;
      bottom: unset !important;
      width: fit-content !important;
    }

    span {
      height: 12px;
      width: 12px;
      @include media-max(768px) {
      }
    }
  }
  h2.title {
    width: 100%;
    font-size: 20px;
    font-weight: 600;
    padding-bottom: 8px;
    margin-bottom: 20px;
    position: relative;
    @include media-max(1200px) {
      font-size: 16px;
    }
  }
  .products_section_item {
    margin-bottom: 50px;
    &:last-child {
      margin-bottom: 0;
    }
    .products_sections_outer {
      //position: relative;
      // display: grid;
      // grid-gap: 0 20px;
      // grid-template-columns: 200px 1fr;
      @include media-max(1480px) {
        grid-template-columns: 150px 1fr;
      }
      @include media-max(1200px) {
        grid-template-columns: 100%;
        grid-gap: 20px 0;
      }
      .products_sections_nav {
        @include media-max(1200px) {
          @include flex-align-center;
          flex-wrap: wrap;
        }
        a {
          font-size: 14px;
          padding: 10px;
          border-bottom: 1px solid #dadada;
          font-weight: 400;
          display: block;
          color: $color-text;
          @include media-max(1480px) {
            font-size: 12px;
          }
          @include media-max(1200px) {
            border-bottom: 0;
            padding: 0;
            margin: 0 15px 10px 0;
          }
          cursor: pointer;
          &:last-child {
            border-bottom: 0;
          }
          @include hover {
            background: $color-grey-10;
          }
        }
      }
      .products_grid_holder {
        @include media-min(1200px) {
          grid-template-columns: repeat(4, 1fr);
        }
      }
    }
  }
}
