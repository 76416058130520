.products_import_page_inner {
  margin-bottom: 20px;
  .import_btn {
    margin: 0 0 20px auto;
  }
  .modal_outer {
    padding: 30px;
    h2 {
      text-align: center;
      margin-bottom: 20px;
      font-weight: 500;
      font-size: 26px;
    }
    .buttons {
      display: grid;
      grid-template-columns: repeat(2, auto);
      align-items: center;
      grid-gap: 20px;
    }
  }
  .products_import_page_outer {
    background: #fff;
    border-radius: 0.5rem;
    box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
    width: 100%;
    .tabs_inner {
      .tabs_outer {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        border-bottom: 1px solid #ddd;
        align-items: center;
        a {
          position: relative;
          padding: 15px;
          text-align: center;
          color: #a4a4a4;
          display: block;
          @include full-size;
          @include transition;
          @include media-max(700px) {
            font-size: 12px;
            padding: 10px 5px;
          }
          &.active {
            color: $color-purple;
            &::after {
              content: '';
              position: absolute;
              left: 0;
              right: 0;
              bottom: -1px;
              height: 1px;
              background: $color-purple;
            }
          }
        }
      }
    }
    .products_import_page_content {
      padding: 30px 20px;
      .category_to_category_tab {
        form {
          .inputs_group {
            display: grid;
            grid-template-columns: 1fr 30px 1fr auto;
            grid-gap: 20px;
            @include media-max(900px) {
              grid-template-columns: 100%;
            }
          }
          .arrow {
            width: 30px;
            height: 40px;
            @include flex-center;
            font-size: 31px;
            color: $color-success;
            @include media-max(900px) {
              margin: 0 auto;
              transform: rotate(90deg);
            }
          }
        }
      }
    }
  }
}

.list_of_skus {
  .dropdown_inner {
    width: 100%;
    height: 40px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    position: relative;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.4);
    &.active {
      .dropdown_outer {
        svg {
          transform: rotate(180deg);
        }
      }
    }
    .dropdown_outer {
      @include full-size;
      padding: 0 20px;
      @include flex-between;
      cursor: pointer;
      p {
        color: $color-text;
      }
      svg {
        transform: rotate(0);
        @include transition;
      }
    }
    .dropdown_items {
      position: absolute;
      left: 0;
      right: 0;
      top: 110%;
      background: $color-white;
      z-index: 2;
      border: 1px solid rgba(0, 0, 0, 0.2);
      box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
      li {
        padding: 8px 20px;
        font-size: 14px;
        cursor: pointer;
        &.active {
          color: $color-text;
          background: $color-grey-10;
        }
      }
    }
  }
  .items {
    margin-top: 20px;
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(6, 1fr);
    @include media-max(1500px) {
      grid-template-columns: repeat(5, 1fr);
    }
    @include media-max(1200px) {
      grid-template-columns: repeat(4, 1fr);
    }
    @include media-max(1000px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @include media-max(700px) {
      grid-template-columns: repeat(2, 1fr);
    }
    li {
      border-radius: 8px;
      padding: 5px 10px;
      font-size: 14px;
      display: grid;
      align-items: center;
      grid-template-columns: 1fr 24px;
      grid-gap: 10px;
      background: $color-grey-10;
    }
  }
}

.products_import_category_to_category_tab {
  .list_of_categories {
    background: #fff;
    border-radius: 0.5rem;
    box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
    width: 100%;
    padding: 10px 20px;
    .item {
      font-size: 14px;
      display: grid;
      grid-template-columns: 1fr 30px 1fr;
      grid-gap: 20px;
      align-items: center;
      padding: 5px 0;
      border-bottom: 1px solid #ddd;
      @include media-max(900px) {
        grid-template-columns: 100%;
      }
      &:last-child {
        border-bottom: 0;
      }
      .arrow {
        width: 30px;
        @include flex-center;
        font-size: 31px;
        color: $color-success;
        margin-left: -50px;
        @include media-max(1400px) {
          margin-left: -40px;
        }
        @include media-max(900px) {
          margin: 0 auto;
          transform: rotate(90deg);
        }
      }
      .right {
        margin-left: -50px;
        display: grid;
        align-items: center;
        grid-template-columns: 1fr 30px;
        @include media-max(1400px) {
          margin-left: -40px;
        }
        @include media-max(900px) {
          margin: 0;
        }
      }
    }
  }
}
