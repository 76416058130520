.cms_content {
  padding: 90px 20px 20px 290px;
  background: $color-grey-10;
  font-family: $font-roboto;
  @include full-width;
  @include transition;
  min-height: 100vh;
  @include media-max(1400px) {
    padding-left: 260px;
  }
  @include media-max(1200px) {
    padding: 90px 20px 20px 20px;
  }
  @include media-max(500px) {
    padding: 75px 10px 10px 10px;
  }
  &.active {
    padding-left: 100px;
    @include media-max(1200px) {
      padding-left: 20px;
    }
  }
  .cms_page {
    .data_no_found {
      text-align: center;
      font-size: 18px;
      margin-top: 50px;
    }
  }
  .box_holder {
    background: $color-white;
    border-radius: 0.5rem;
    box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
    width: 100%;
    padding: 20px;
    @include media-max(500px) {
      padding: 15px;
    }
  }
}

.dashboard-container {
  display: grid;
  position: relative;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;

  @include media-max(2000px) {
    grid-template-columns: 1fr 1fr;
  }
  @include media-max(768px) {
    grid-template-columns: 1fr;
  }

  .search_stats_container {
    aspect-ratio: 1/1;
    position: relative;
    @include media-max(2000px) {
      padding-top: 100px;
    }
    @include media-max(768px) {
      aspect-ratio: 1/1.5;
    }
    overflow-y: scroll;
    gap: 20px;
  }
  .date-picker-container {
    margin-top: 20px;

    @include media-max(768px) {
      margin-top: 75px;
    }
  }
}
