.home_page_actions {
  .container {
    position: relative;
  }

  .swiper-container {
    position: unset;
  }
  .swiper-pagination-bullets {
    bottom: -30px !important;
    @include media-min(1000px) {
      right: 20px !important;
      top: 5px !important;
      left: unset !important;
      bottom: unset !important;
      width: fit-content !important;
    }

    span {
      height: 12px;
      width: 12px;
      @include media-max(768px) {
      }
    }
  }
  .action_title {
    margin-top: 60px;
    margin-bottom: 40px;
    font-size: 20px;
    font-weight: 600;
    @include media-max(768px) {
      margin-top: 40px;
      margin-bottom: 25px;
    }
  }
  .action {
    display: flex;
    gap: 30px;
    overflow: visible;
    @include media-max(768px) {
      flex-direction: column;
      gap: 25px;
    }

    .left {
      width: 35%;
      min-width: 35%;
      @include media-max(768px) {
        width: 100%;
      }
      img {
        width: 100%;
        height: 100%;
        border-radius: 10px;
        overflow: hidden;
      }
    }
    .right {
      //position: relative;
      width: 62%;
      @include media-max(768px) {
        width: 100%;
      }
      .swiper-container {
        padding-bottom: 1px;
      }
    }
  }
}
