.results_dropdown_inner {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(92, 176, 241, 0.57);
  z-index: 100;
  padding: 30px;
  display: flex;
  justify-content: center;
  animation: searchopacity 0.2s linear forwards;
  @include media-max(800px) {
    padding: 20px;
  }
  .banner {
    display: flex !important;
    margin-top: 5%;
    p {
      margin: auto;
    }
  }
  .results_dropdown_outer {
    width: 1500px;

    margin-top: 130px;
    background-color: white;
    border-radius: 6px;
    border: 1px solid $color-primary;
    position: relative;
    z-index: 100;
    display: grid;
    grid-template-columns: 310px 1fr;
    @include media-max(1550px) {
      width: 1200px;
      margin-top: 120px;
    }
    @include media-max(1250px) {
      width: 1150px;
      grid-template-columns: 250px 1fr;
      margin-top: 110px;
    }
    @include media-max(1200px) {
      grid-template-columns: 200px 1fr;
      margin-top: 70px;
    }
    @include media-max(800px) {
      grid-template-columns: 100%;
      margin-top: 165px;
      padding: 20px;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        width: 0;
      }
    }
    &::after {
      content: '';
      position: absolute;
      top: -6.5px;
      left: 34%;
      width: 10px;
      height: 10px;
      transform: rotate(45deg);
      background: $color-white;
      z-index: -1;
      border-top: 1px solid $color-primary;
      border-left: 1px solid $color-primary;
      @include media-max(1200px) {
        left: 45px;
      }
    }
    .product_wrapper {
      display: flex;
      width: 100%;
      justify-content: center;
      max-height: 100%;
      @include media-max(800px) {
        overflow-y: hidden;
        display: flex;
        flex-direction: column-reverse;
      }
    }
    .left {
      border-right: 1px solid #ddd;
      padding-right: 30px;
      overflow-y: scroll;
      padding: 30px;
      display: flex;
      flex-direction: column;
      &::-webkit-scrollbar {
        width: 0;
      }
      @include media-max(800px) {
        order: 2;
        overflow-y: unset;
        border-right: 0;
        padding: 0 0 20px 0;
        flex-direction: column-reverse;
      }
      .banners {
        .banner {
          img {
            height: 100%;
          }
        }
        @include media-max(800px) {
          visibility: hidden;
          height: 0;
        }
      }
      .group {
        border-bottom: 1px solid #ddd;
        padding-bottom: 20px;
        margin-bottom: 20px;
        &:last-child {
          border-bottom: 0;
        }
        @include media-max(1200px) {
          margin-bottom: 10px;
          padding-bottom: 10px;
        }
        @include media-max(800px) {
          border-top: 1px solid #ddd;
          border-bottom: 0;
          padding: 10px 0 0 0;
          margin: 10px 0 0 0;
        }
        h3 {
          font-size: 17px;
          font-weight: 600;
          color: $color-black;
          margin-bottom: 20px;
          @include media-max(1200px) {
            font-size: 14px;
            margin-bottom: 10px;
          }
          @include media-max(800px) {
            font-size: 16px;
          }
        }
        p {
          font-weight: 400;
          color: $color-black;
          font-size: 15px;
          margin-bottom: 15px;
          cursor: pointer;
          @include media-max(1200px) {
            font-size: 12px;
            margin-bottom: 10px;
          }
          @include media-max(800px) {
            font-size: 14px;
          }
          &:last-child {
            margin-bottom: 0;
          }
          span {
            color: $color-success;
          }
        }
        .row {
          display: grid;
          grid-template-columns: 45px 1fr;
          grid-gap: 10px;
          align-items: center;
          margin-bottom: 15px;
          cursor: pointer;
          img {
            width: 45px;
            height: 45px;
          }
        }
        &.categories {
          p {
            @include text-ellipsis;
          }
        }
      }
    }
    .right {
      @include flex-column;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        width: 0;
      }
      @include media-max(800px) {
        order: 1;
        padding: 0;
        overflow-y: unset;
      }
      .banners {
        visibility: hidden;
        height: 0%;
        position: absolute;
        @include media-max(800px) {
          visibility: visible;
          height: min-content;
          position: relative;
        }
        .banner {
          width: 80%;
          height: min-content;
          margin: auto;
          margin-top: 5%;
          img {
            height: 100%;
          }
        }
      }
      .nav_tabs {
        @include flex-align-center;
        flex-wrap: wrap;
        grid-gap: 20px;
        button {
          border-radius: 6px;
          padding: 8px 17px;
          color: $color-white;
          font-size: 15px;
          font-weight: 400;
          background: #d2d2d2;
          &.active {
            background: #0062bd;
          }
          @include media-max(1200px) {
            font-size: 12px;
          }
        }
      }
      .product_title {
        font-size: 17px;
        padding-top: 2em;
        font-weight: 600;
        color: #000;
        margin-top: 20px;
        margin-bottom: 20px;
        margin-left: 2em;
        top: 0;
        padding-bottom: 20px;
        @include media-max(800px) {
          padding-top: 0;
        }
        @include media-max(1550px) {
          font-size: 15px;
        }
      }
      .products {
        width: 100%;
        max-height: 85%;
        margin-bottom: 5%;
        display: flex;
        overflow-y: scroll;
        margin-top: 0;
        flex-wrap: wrap;
        justify-content: start;
        grid-template-columns: repeat(4, 1fr);
        @include media-max(1550px) {
          grid-template-columns: repeat(3, 1fr);
        }
        @include media-max(1000px) {
          grid-template-columns: repeat(2, 1fr);
        }
        @include media-max(800px) {
          max-height: 100%;
          overflow-y: hidden;
          margin-right: 0;
        }
        .product_item {
          width: 90%;
          height: 100%;
          margin: auto;
          display: flex;
          background: #fff;
          border-radius: 2%;
          box-shadow: 1px 1px 2px #e6e6e6;
          justify-content: space-between;
          @include flex-column;
          cursor: pointer;
          padding: 20px;
          &:nth-child(4n) {
            border-right: 0;
          }
          @include media-max(1550px) {
            &:nth-child(4n) {
              border-right: 1px solid #ddd;
            }
            &:nth-child(3n) {
              border-right: 0;
            }
          }
          @include media-max(1550px) {
            &:nth-child(4n) {
              border-right: 1px solid #ddd;
            }
            &:nth-child(3n) {
              border-right: 0;
            }
          }
          @include media-max(1000px) {
            &:nth-child(4n) {
              border-right: 1px solid #ddd;
            }
            &:nth-child(3n) {
              border-right: 1px solid #ddd;
            }
            &:nth-child(2n) {
              border-right: 0;
            }
          }
          @include media-max(800px) {
            width: 100%;
            border: 0 !important;
            display: grid;
            grid-template-columns: 70px 1fr;
            grid-gap: 20px;
            align-items: center;
            padding: 5%;
            overflow-x: hidden;
            overflow-y: hidden;
            margin: auto;
            box-shadow: 0px 0 2px #e6e6e6;
          }
          .mobile {
            @include media-max(800px) {
              order: 2;
            }
            .name {
              font-size: 14px;
              color: $color-primary;
              font-weight: bold;
              height: 36px;
              overflow: hidden;
              @include media-max(1200px) {
                font-size: 12px;
                height: 30px;
              }
              @include media-max(800px) {
                font-size: 14px;
                height: auto;
                max-height: 36px;
              }
              span {
                color: $color-success;
              }
            }
            .price {
              display: none;
              @include media-max(800px) {
                margin-top: 5px;
                display: block;
                font-weight: bold;
                font-size: 13px;
                color: $color-black;
              }
            }
          }
          img {
            max-width: 200px;
            max-height: 200px;
            object-fit: contain;
            align-self: center;
            margin: 10px 0;
            @include media-max(1200px) {
              max-width: 150px;
              max-height: 150px;
            }
            @include media-max(800px) {
              max-width: 70px;
              max-height: 70px;
              margin: 0;
              order: 1;
            }
          }
          .price {
            font-weight: bold;
            font-size: 20px;
            color: $color-black;
            @include media-max(1200px) {
              font-size: 16px;
            }
            @include media-max(800px) {
              order: 1;
              display: none;
            }
          }
        }
      }
      ::-webkit-scrollbar {
        background-color: white;
        width: 5px;
        float: left;
      }
      ::-webkit-scrollbar-track {
        background: #f8f8f8;
        border-radius: 5em;
      }
      ::-webkit-scrollbar-thumb {
        background: #fff;
        border: 0.1em solid #6caada;
        border-radius: 5em;
      }
      .popular_products_wrapper {
        height: 100%;
        border-right: 1px solid #f2f2;
        @include media-max(800px) {
          width: 100%;
          border-right: 0px;
        }
      }
      .popular_products {
        width: 100%;
        height: 85%;
        position: relative;
        display: flex;
        flex-wrap: nowrap;
        flex-direction: column;
        overflow-x: hidden;
        justify-content: baseline;
        text-align: left;
        overflow-y: scroll;
        direction: rtl;
        ::after {
          position: sticky;
          top: 0;
        }
        @include media-max(1550px) {
          grid-template-columns: repeat(3, 1fr);
        }
        @include media-max(1000px) {
          grid-template-columns: repeat(2, 1fr);
        }
        @include media-max(800px) {
          overflow-y: visible;
          height: 100%;
        }
        .popular_product_item {
          margin: auto;
          min-width: 85%;
          padding: 10px;
          height: 100%;
          display: flex;
          background: #fff;
          border-radius: 2%;
          margin-right: 5%;
          box-shadow: 1px 1px 2px #e6e6e6;
          justify-content: space-between;
          @include flex-column;
          cursor: pointer;
          padding: 20px;
          &:nth-child(4n) {
            border-right: 0;
          }
          @include media-max(1550px) {
            &:nth-child(4n) {
              border-right: 1px solid #ddd;
            }
            &:nth-child(3n) {
              border-right: 0;
            }
          }
          @include media-max(1000px) {
            &:nth-child(4n) {
              border-right: 1px solid #ddd;
            }
            &:nth-child(3n) {
              border-right: 1px solid #ddd;
            }
            &:nth-child(2n) {
              border-right: 0;
            }
          }
          @include media-max(800px) {
            width: 100%;
            border: 0 !important;
            display: grid;
            grid-template-columns: 70px 1fr;
            grid-gap: 20px;
            align-items: center;
            padding: 5%;
            text-align: right;
            overflow-x: hidden;
            overflow-y: hidden;
            margin: auto;
            flex-direction: row-reverse;
            box-shadow: 0px 0 2px #e6e6e6;
          }
          .mobile {
            @include media-max(800px) {
              order: 2;
            }
            .name {
              font-size: 14px;
              color: $color-primary;
              font-weight: bold;
              height: 36px;
              overflow: hidden;
              @include media-max(1200px) {
                font-size: 12px;
                height: 30px;
              }
              @include media-max(800px) {
                font-size: 14px;
                height: auto;
                max-height: 36px;
              }
              span {
                color: $color-success;
              }
            }
            .price {
              display: none;
              @include media-max(800px) {
                margin-top: 5px;
                display: block;
                font-weight: bold;
                font-size: 13px;
                color: $color-black;
              }
            }
          }
          img {
            max-width: 200px;
            max-height: 200px;
            object-fit: contain;
            align-self: center;
            margin: 10px 0;
            @include media-max(1200px) {
              max-width: 150px;
              max-height: 150px;
            }
            @include media-max(800px) {
              max-width: 70px;
              max-height: 70px;
              margin: 0;
              order: 1;
            }
          }
          .price {
            font-weight: bold;
            font-size: 20px;
            color: $color-black;
            @include media-max(1200px) {
              font-size: 16px;
            }
            @include media-max(800px) {
              order: 3;
              display: none;
            }
          }
        }
      }
      .products_empty {
        text-align: center;
        margin-top: 100px;
        font-weight: bold;
        font-size: 18px;
        @include media-max(1200px) {
          font-size: 14px;
        }
      }
      .banner {
        width: 100%;
        height: 160px;
        margin-top: auto;
        @include media-max(1200px) {
          height: 120px;
        }
        @include media-max(800px) {
          display: none;
        }
        img {
          @include full-size;
          object-fit: cover;
        }
      }
    }
  }
}

@keyframes searchopacity {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
