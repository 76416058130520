.about_partners_section {
  margin-bottom: 100px;
  .container {
    h2 {
      font-size: 2rem;
      font-weight: 400;
      color: $color-text;
      text-align: center;
      margin-bottom: 20px;
      @include media-max(500px) {
        font-size: 1.7em;
      }
    }
    .about_partners_section_content {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      grid-gap: 20px;
      @include media-max(992px) {
        grid-template-columns: repeat(5, 1fr);
      }
      @include media-max(768px) {
        grid-template-columns: repeat(4, 1fr);
      }
      @include media-max(768px) {
        grid-template-columns: repeat(4, 1fr);
      }
      @include media-max(544px) {
        grid-template-columns: repeat(3, 1fr);
      }
      @include media-max(400px) {
        grid-template-columns: repeat(2, 1fr);
      }
      .box {
        @include full-size;
        position: relative;
        cursor: pointer;
        &:hover {
          .tooltip {
            opacity: 1;
          }
        }
        img {
          @include full-size;
        }
        .tooltip {
          @include transition;
          opacity: 0;
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          @include flex-center;
          text-align: center;
          padding: 3px 10px;
          background: rgba(0, 0, 0, 0.5);
          color: $color-white;
          font-size: 14px;
        }
      }
    }
  }
}
