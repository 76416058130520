.suppliers_screen {
  .table-suppliers {
    .header,
    .table_row {
      @include flex-between;
      & > .table_cell:last-child {
        @include flex-align-center;
        justify-content: flex-end;
      }
    }
  }
  @include media-max(500px) {
    .table_helper {
      .align_row {
        .table_inline_form {
          width: calc(100% - 100px);
          .input_holder {
            width: calc(100% - 65px);
          }
          button {
            margin-left: 10px;
          }
        }
      }
    }
  }
}
