.custom_button {
  width: 100%;
  height: 50px;
  @include flex-center;
  border-radius: 6px;
  color: $color-white;
  background: $color-primary;
  font-weight: 600;
  font-size: 14px;
  @include hover {
    background: $color-black;
  }
  svg {
    margin-right: 7px;
  }
  @include media-max(778px) {
    font-size: 12px;
    height: 40px;
    svg {
      font-size: 18px;
      margin-right: 4px;
    }
  }
}
