#app_bar {
  .bottom_nav {
    margin-top: 25px;
    border-bottom: 3px solid $color-primary;
    &.active {
      margin-top: 117px;
    }
    @include media-max(1200px) {
      display: none;
      &.active {
        margin-top: 0;
      }
    }
    .container {
      .bottom_nav_content {
        @include flex-between;
        .left_side {
          @include flex-align-center;
          width: 100%;
          .root_navigation_wrapper {
            .root_navigation {
              width: 260px;
              position: relative;
              button {
                width: 260px;
                height: 44px;
                background: $color-primary;
                color: $color-white;
                border-radius: 10px 10px 0px 0px;
                padding: 0 20px;
                font-size: 14px;
                font-weight: 700;
                @include flex-align-center;
                @include hover {
                  background: $color-primary-hover;
                }
                svg {
                  font-size: 18px;
                  margin-right: 5px;
                }
              }
              .category_menu_inner {
                position: absolute;
                top: 100%;
                left: 0;
                right: 0;
                background: $color-white;
                box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.28);
                border-radius: 0 0 0.5em 0.5em;
                z-index: 10;
                padding: 10px 0;
                .category_menu_outer {
                  @include full-size;
                  position: relative;
                  .category_menu_item_inner {
                    padding: 0 1rem;
                    // position: relative;
                    &:last-child {
                      .category_menu_item_outer {
                        border-bottom: 0;
                      }
                    }
                    &:hover {
                      .category_item_own_subcategories_inner {
                        display: block;
                        // top:0;
                        // position: absolute;
                      }
                    }
                    .category_menu_item_outer {
                      font-size: 14px;
                      font-weight: 400;
                      color: #333e48;
                      display: grid;
                      grid-template-columns: 1fr 18px;
                      align-items: center;
                      padding: 10px 5px;
                      border-bottom: 1px solid #ddd;
                      cursor: pointer;
                      @include hover {
                        background-color: #f5f5f5;
                      }
                      svg {
                        width: 18px;
                        height: 18px;
                        font-size: 18px;
                        color: #aeaeae;
                      }
                    }
                    .category_item_own_subcategories_inner {
                      min-height: calc(100% + 21px);
                      display: none;
                      position: absolute;
                      background: $color-white;
                      top: -12px;
                      left: 260px;
                      width: 830px;
                      z-index: 10;
                      box-shadow: 2px 4px 5px -2px rgba(0, 0, 0, 0.18);
                      border-radius: 0 0 $radius-lg 0;
                      border-top: 2px solid $color-primary;
                      border-bottom-left-radius: 10px;
                      .category_item_own_subcategories_outer {
                        @include full-size;
                        padding: 30px 40px;
                        position: relative;
                        span {
                          padding: 10px;
                        }
                        .subcategory_item_wrap {
                          padding-bottom: 15px;
                          margin-bottom: 15px;
                          border-bottom: 1px solid #ddd;
                          h4,
                          p {
                            font-size: 14px;
                            font-weight: 400;
                            color: #333e48;
                            line-height: 1.8;
                            cursor: pointer;
                          }
                          h4 {
                            font-weight: bold;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          .mini_nav {
            margin-left: 30px;
            @include flex-align-center;
            a {
              height: 44px;
              border-radius: $radius-lg;
              color: $color-text;
              font-weight: 700;
              margin-right: 2em;
              font-size: $font-size-regular;
              @include flex-center;
              @include hover {
                color: $color-black;
              }
              // &.active {
              //   background: #8bc34a;
              //   color: $color-white;
              //   padding: 0 20px;
              //   @include hover {
              //     background: $color-success-hover;
              //     color: $color-white;
              //   }
              // }
            }
          }
          .right_link {
            font-size: $font-size-regular;
            color: $color-text;
            font-weight: 700;
            margin-left: auto;
          }
        }
      }
    }
  }
}

.my-masonry-grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: -30px;
  width: auto;
}

.my-masonry-grid_column {
  padding-left: 30px;
  background-clip: padding-box;
}
