.table-orders {
  .header,
  .table_row {
    display: grid;
    align-items: center;
    grid-template-columns: 60px 250px 270px 200px 250px 110px 110px 110px 190px;
    @include media-max(1350px) {
      grid-template-columns: 40px 220px 220px 150px 180px 80px 80px 80px 160px;
    }
    @include media-max(1000px) {
      grid-template-columns: 1fr;
    }
    .table_cell:nth-child(1) {
      a {
        color: $color-purple;
      }
    }
    .table_cell:last-child {
      position: relative;
      overflow: unset !important;
      .tooltip_wrap {
        @include flex-center;
      }
      .status_chip {
        @include flex-center;
        padding: 4px 10px;
        font-size: 0.8rem;
        font-weight: 500;
        border-radius: $radius-pill;
        @include media-max(1350px) {
          font-size: 11px;
        }
        &.success {
          background: rgba(40, 199, 111, 0.15);
          color: rgb(40, 199, 111);
        }
        &.danger {
          background: rgba(234, 84, 85, 0.15);
          color: rgb(234, 84, 85);
        }
        &.primary {
          background: rgba(115, 103, 240, 0.15);
          color: rgb(115, 103, 240);
        }
        &.warning {
          background: rgba(255, 159, 67, 0.15);
          color: rgb(255, 159, 67);
        }
        &.info {
          background: #1ce7ff4a;
          color: #0077d6;
        }
        &.secondary {
          background: #9e9e9e38;
          color: #000000c2;
        }
      }
    }
  }
  .header {
    @include media-max(1000px) {
      padding-left: 45px !important;
      grid-template-columns: 250px 450px 100px;
      @include media-max(960px) {
        grid-template-columns: 250px 450px;
      }
      @include media-max(860px) {
        grid-template-columns: 200px 400px;
      }
      @include media-max(770px) {
        grid-template-columns: 1fr;
      }
      @include media-max(500px) {
        grid-template-columns: 100%;
      }
      .table_cell {
        &:nth-child(2) {
          display: flex !important;
        }
        &:nth-child(3) {
          display: flex !important;
          @include media-max(770px) {
            display: none !important;
          }
        }
        &:nth-child(4) {
          display: flex !important;
          @include media-max(960px) {
            display: none !important;
          }
        }
        display: none;
      }
    }
  }
  .table_row {
    cursor: pointer;
    @include media-max(1000px) {
      @include flex-align-center;
      flex-wrap: wrap;
      height: unset !important;
      padding: 10px !important;
      .expand_button {
        display: block;
      }
      .table_cell {
        &:nth-child(3) {
          width: 250px !important;
          display: flex !important;
          flex-direction: column;
          margin-left: 10px;
          @include media-max(860px) {
            width: 200px !important;
          }
        }
        &:nth-child(4) {
          width: 450px !important;
          display: flex !important;
          @include media-max(860px) {
            width: 400px !important;
          }
          @include media-max(770px) {
            display: none !important;
          }
        }
        &:nth-child(5) {
          width: 100px !important;
          display: flex !important;
          @include media-max(960px) {
            display: none !important;
          }
        }
        display: none;
      }
    }
  }
  .mobile_table_wrapper {
    @include media-max(1000px) {
      display: block;
      .status_chip {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        @include flex-center;
        padding: 4px 10px;
        font-size: 0.8rem;
        font-weight: 500;
        border-radius: $radius-pill;
        @include media-max(1350px) {
          font-size: 11px;
        }
        &.success {
          background: rgba(40, 199, 111, 0.15);
          color: rgb(40, 199, 111);
        }
        &.danger {
          background: rgba(234, 84, 85, 0.15);
          color: rgb(234, 84, 85);
        }
        &.primary {
          background: rgba(115, 103, 240, 0.15);
          color: rgb(115, 103, 240);
        }
        &.warning {
          background: rgba(255, 159, 67, 0.15);
          color: rgb(255, 159, 67);
        }
        &.info {
          background: #1ce7ff4a;
          color: #0077d6;
        }
        &.secondary {
          background: #9e9e9e38;
          color: #000000c2;
        }
      }
      .mobile_table_row:nth-child(1) {
        display: none;
      }
      .mobile_table_row:nth-child(2) {
        display: none;
      }
      @include media-max(960px) {
        .mobile_table_row:nth-child(2) {
          display: grid;
        }
      }
      @include media-max(770px) {
        .mobile_table_row:nth-child(1) {
          display: grid;
        }
      }
    }
  }
}

.single_order_page_wrap {
  display: flex;
  align-items: flex-start;
  gap: 50px;
  //justify-content: space-between;
  @include media-max(1000px) {
    //@include flex-column;
    flex-wrap: wrap;
  }
  h2.title {
    font-weight: 300;
    font-size: 20px;
    border-bottom: 1px solid #f1f1f1;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
  .left {
    width: calc(100% - 340px);
    @include media-max(1000px) {
      width: 100%;
    }
    .top_part {
      width: 100%;
      //width: calc(100% - 340px);

      .details_wrap {
        .item_group {
          @include flex-align-center;
          padding: 10px 0;
          display: grid;
          grid-template-columns: 160px 1fr;
          @include media-max(600px) {
            grid-template-columns: 120px 1fr;
          }
          span {
            font-weight: 500;
            color: #3a3a3a;
            margin-right: 5px;
            font-size: 14px;
            @include media-max(700px) {
              font-size: 12px;
            }
          }
          p {
            font-weight: 300;
            font-size: 14px;
            word-break: break-all;
            @include media-max(700px) {
              font-size: 12px;
            }
          }
          a {
            color: $color-purple;
            font-weight: 300;
            font-size: 14px;
            word-break: break-all;
            @include media-max(700px) {
              font-size: 12px;
            }
          }
        }
      }
      .selects_group {
        @include flex-align-center;
        @include media-max(1450px) {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-gap: 20px;
          button {
            width: 100px;
          }
        }
        @include media-max(850px) {
          grid-template-columns: 100%;
        }
        span {
          color: #fff !important;
          margin: 0 !important;
        }
        .search_select_wrapper {
          width: 250px;
          margin-right: 20px;
          @include media-max(1800px) {
            width: 200px;
            margin-right: 10px;
          }
          @include media-max(1600px) {
            width: 150px;
          }
          @include media-max(1450px) {
            width: 100%;
            margin: 0;
          }
          span {
            color: rgba(0, 0, 0, 0.4) !important;
          }
          label {
            display: none;
          }
          .search_select_holder {
            .root {
              padding: 0 10px;
              p {
                span {
                  color: $color-text !important;
                }
              }
            }
          }
        }
        .dropdown_inner {
          width: 250px;
          margin-right: 20px;
          height: 40px;
          background: $color-white;
          position: relative;
          border-radius: 5px;
          color: rgba($color: $color-black, $alpha: 0.4);
          @include border(1, rgba($color: $color-black, $alpha: 0.2));
          @include transition;
          font-size: 14px;
          @include media-max(1800px) {
            width: 200px;
            margin-right: 10px;
          }
          @include media-max(1600px) {
            width: 150px;
          }
          @include media-max(1450px) {
            width: 100%;
            margin: 0;
          }
          &.active {
            border-color: #7367f0;
            box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
            .dropdown_outer {
              svg {
                transform: rotate(180deg);
              }
            }
          }
          .dropdown_outer {
            @include full-size;
            display: grid;
            align-items: center;
            grid-template-columns: 1fr 20px;
            grid-gap: 10px;
            padding: 0 10px;
            cursor: pointer;
            p {
              &.active {
                color: $color-text;
              }
            }
            p {
              font-weight: 400;
              @include media-max(1800px) {
                font-size: 12px;
              }
            }
            svg {
              transform: rotate(0);
              @include transition;
            }
          }
          .dropdown_items {
            position: absolute;
            top: 110%;
            left: 0;
            right: 0;
            border-radius: 5px;
            @include border(1, rgba($color: $color-black, $alpha: 0.2));
            background: $color-white;
            overflow: hidden;
            li {
              padding: 5px 10px;
              cursor: pointer;
              @include media-max(1800px) {
                font-size: 12px;
              }
              &.active {
                background: $color-grey-10;
                color: $color-text;
              }
            }
          }
        }
      }
    }
  }
}

.table_orders_items_products {
  margin-top: 20px;
  width: 100%;
  @include media-max(500px) {
    margin-top: 10px;
  }
  .header,
  .table_row {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 100px 100px 100px 100px;
    @include media-max(1200px) {
      grid-template-columns: 1fr 100px 60px 60px 60px;
    }
    @include media-max(850px) {
      grid-template-columns: 100%;
    }
  }
  .header {
    @include media-max(850px) {
      padding-left: 45px !important;
      @include media-max(500px) {
        grid-template-columns: 100%;
      }
      .table_cell {
        &:nth-child(1) {
          display: flex !important;
        }
        display: none;
      }
    }
  }
  .table_row {
    height: 60px !important;
    @include media-max(850px) {
      height: unset !important;
      padding: 10px !important;
      @include flex-align-center;
      flex-wrap: wrap;
      height: unset !important;
      padding: 10px !important;
      .expand_button {
        display: block;
        margin-right: 10px;
      }
      .table_cell {
        @include media-max(850px) {
          display: none;
        }
      }
    }
    .table_cell:nth-child(1) {
      @include flex-align-center;
      img {
        max-height: 45px;
        margin-right: 10px;
        border-radius: 4px;
        @include media-max(850px) {
          max-height: 35px;
        }
        @include media-max(500px) {
          max-height: 30px;
        }
        @include media-max(350px) {
          max-height: 20px;
        }
      }
      .product_info {
        a {
          color: $color-purple;
          margin-bottom: 3px;
          display: block;
        }
        span {
          font-weight: 500;
        }
      }
    }
    @include media-max(850px) {
      .mobile_table_wrapper {
        display: block;
      }
    }
  }
  .total_result_print {
    @include flex-align-center;
    justify-content: flex-end;
    font-size: 14px;
    strong {
      margin-left: 5px;
    }
  }
  .photoPlaceholder {
    width: 40px;
    height: 40px;
    opacity: 0.3;
    @include media-max(850px) {
      width: 30px;
      height: 30px;
    }
    @include media-max(350px) {
      width: 20px;
      height: 20px;
    }
  }
}

.right {
  .order_notes_box {
    width: 320px;
    background: $color-white;
    border-radius: 0.5rem;
    box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
    padding: 20px;
    @include media-max(1000px) {
      width: 100%;
      margin-top: 20px;
    }
    @include media-max(500px) {
      margin-top: 10px;
    }
    .note {
      margin: 20px 0;
      width: 100%;
      font-size: 13px;
      background: rgba(255, 186, 0, 0.15);
      padding: 10px;
      border-radius: 6px;
      color: rgb(255, 186, 0);
      font-weight: 300;
      position: relative;
      .bottom {
        margin-top: 10px;
        color: #b9b9b9;
        font-weight: 500;
        @include flex-between;
      }
    }
    form.form {
      width: 100%;
      position: relative;
      h4 {
        margin: 20px 0;
        font-size: 16px;
        font-weight: 300;
      }
      textarea {
        width: 100%;
        height: 120px;
        border: 1px solid #f1f1f1;
        border-radius: 4px;
        padding: 10px;
        font-size: 12px;
        margin-bottom: 15px;
        color: $color-text;
        @include transition;
        &:focus {
          box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
          border-color: $color-purple;
        }
      }
    }
    .buttons_row {
      @include flex-align-center;
      justify-content: flex-end;
    }
    .is_empty {
      margin: 30px 0;
      font-weight: 300;
      font-size: 16px;
    }
    .type_of_note_dropdown {
      margin-bottom: 20px;
      background: $color-white;
      width: 100%;
      height: 40px;
      position: relative;
      border-radius: 5px;
      color: $color-text;
      @include border(1, rgba($color: $color-black, $alpha: 0.2));
      @include transition;
      &.active {
        border-color: #7367f0;
        box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
        .root {
          span {
            transform: translateY(-50%) rotate(180deg);
          }
        }
      }
      .root {
        padding: 0 10px;
        @include full-width;
        @include flex-between;
        cursor: pointer;
        span {
          position: absolute;
          top: 50%;
          right: 10px;
          transform: translateY(-50%) rotate(0);
          @include transition;
          svg {
            font-size: 20px;
          }
        }
      }
      .dropdown_holder {
        @include transition;
        z-index: 2;
        position: absolute;
        top: calc(100% + 5px);
        left: -1px;
        right: -1px;
        border-radius: 5px;
        @include border(1, rgba($color: $color-black, $alpha: 0.2));
        background: $color-white;
        ul {
          @include full-width;
          li {
            font-size: 13px;
            padding: 7px 10px;
            text-overflow: ellipsis;
            width: 100%;
            overflow: hidden;
            color: rgba($color: $color-black, $alpha: 0.4);
            @include hover {
              background: $color-grey-10;
              cursor: pointer;
            }
            &.active {
              color: $color-text;
            }
          }
        }
      }
    }
    button.button {
      margin-left: auto;
    }
  }
  .uploadFile {
    width: 320px;

    background-color: $color-white;
    border-radius: 0.5rem;
    box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin-top: 30px;
    @include media-max(1000px) {
      width: 100%;
      margin-top: 20px;
    }
    @include media-max(500px) {
      margin-top: 10px;
    }
    h4 {
      margin-bottom: 15px;
    }
    button {
      justify-content: end;
      margin-top: 15px;
    }
  }
  .file {
    margin-top: 30px;
    background-color: $color-white;
    border-radius: 0.5rem;
    box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
    padding: 20px;
  }
  button {
    justify-content: end;
    margin-top: 15px;
  }
}

.orders_status_list {
  margin-top: 20px;
  &.divider {
    border-top: 1px solid #ddd;
    padding-top: 20px;
  }
  .MuiChip-outlined {
    margin: 0 5px 5px 0;
    font-family: $font-roboto;
    font-size: 12px;
    @include media-max(500px) {
      font-size: 10px;
    }
  }
}

.orders_cms_page {
  .table_helper {
    @include media-max(500px) {
      .align_row:nth-child(1) {
        flex-direction: column;
        align-items: start;
      }
    }
  }
  .date_ranger_filters {
    position: relative;
    @include flex-align-center;
    @include media-max(500px) {
      margin-top: 20px;
      flex-direction: column;
      align-items: start;
    }
    .MuiChip-clickable {
      margin-left: 20px;
      font-family: $font-roboto;
      font-size: 12px;
      @include media-max(500px) {
        font-size: 10px;
        margin: 20px 0 0 0;
      }
      svg {
        font-size: 16px;
      }
    }
    .dropdown_inner {
      position: absolute;
      left: 0;
      top: 120%;
      z-index: 99;
      background: #fff;
      box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.1);
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      padding: 10px 0;
      .DateRangePicker__CalendarSelection {
        background-color: $color-purple;
        border-color: $color-purple;
      }
      .DateRangePicker__Date--is-highlighted {
        div {
          border-color: $color-purple !important;
        }
      }
    }
  }
}

.status_chip_ananas {
  @include flex-center;
  padding: 4px 10px;
  font-weight: 500;
  border-radius: $radius-pill;
  background: rgb(254, 80, 0);
  color: white;
  width: auto;
  @include media-max(1350px) {
    font-size: 11px;
  }
}

.status_chip_promocod {
  @include flex-center;
  padding: 4px 10px;
  font-weight: 500;
  border-radius: $radius-pill;
  background-color: rgba(139, 195, 74, 0.16470588235294117);
  color: #8bc34a;
  width: auto;
  @include media-max(1350px) {
    font-size: 11px;
  }

  .payment_details {
    width: fit-content;
    gap: 10px;
    td {
      min-width: 200px;
    }
    tr {
      margin-top: 10px;
    }
    min-height: 100px;
    font-size: smaller;
  }
}

.approved_green {
  background-color: rgb(7, 175, 7);
  padding: 10px;
  font-size: smaller;
  width: fit-content;
  color: white;
  border-radius: 10vw;
}

.approved_green_mobile {
  @include media-min(1000px) {
    display: none;
  }

  background-color: rgb(7, 175, 7);
  padding: 5px;
  font-size: smaller;
  width: fit-content;
  color: white;
  border-radius: 10vw;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.approved_red {
  background-color: red;
  padding: 10px;
  font-size: smaller;
  width: fit-content;
  max-width: 200px;
  color: white;
  border-radius: 10vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.approved_red_mobile {
  @include media-min(1000px) {
    display: none;
  }

  background-color: red;
  padding: 5px;
  font-size: smaller;
  width: fit-content;
  max-width: 200px;
  color: white;
  border-radius: 10vw;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
