.thanks_page {
  padding-bottom: 50px;
  .image_wrap {
    background: #6eb5eb;
    height: 250px;
    @include flex-center;
    margin-bottom: 50px;
    .icon {
      width: 100px;
      height: 100px;
      @include flex-center;
      background: $color-white;
      border-radius: 50%;
      img {
        width: 60px;
        height: 60px;
      }
    }
  }
  .container {
    .thanks_page_content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      h1 {
        text-align: center;
        font-weight: 500;
        font-size: 34px;
        color: $color-primary;
      }
      p {
        text-align: center;
        margin-bottom: 30px;
        width: 100%;
        line-height: 2;
        font-size: 15px;
        a {
          font-size: 15px;
        }
        @include media-max(700px) {
          font-size: 13px;
          width: 100%;
          a {
            font-size: 13px;
          }
        }
      }
      h3 {
        @include flex-align-center;
        font-size: 24px;
        font-weight: 500;
        margin: 30px 0;
        @include media-max(700px) {
          font-size: 16px;
          flex-direction: column;
          justify-content: center;
        }
        span {
          width: 30px;
          height: 30px;
          background: $color-primary;
          border-radius: 50%;
          @include flex-center;
          color: $color-white;
          margin-right: 10px;
          @include media-max(700px) {
            width: 20px;
            height: 20px;
            margin-bottom: 10px;
            svg {
              font-size: 16px;
            }
          }
        }
      }
      .custom_button {
        width: 250px;
      }
    }
  }
}
