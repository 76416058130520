.about_section_info_banner_wrapper {
  background: $color-primary;
  margin: 50px 0;
  @include media-max(500px) {
    margin: 30px 0;
  }
  .about_section_info_banner {
    height: 200px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    align-items: center;
    align-self: baseline;
    justify-items: center;
    @include media-max(767px) {
      grid-template-columns: 100%;
      grid-gap: 10px 0;
      height: auto;
      padding: 20px 0 10px 0;
    }
    .box {
      @include flex-column;
      align-items: center;
      color: $color-white;
      text-align: center;
      h5 {
        font-size: 1.8rem;
        @include media-max(500px) {
          font-size: 1.4em;
        }
      }
      p {
        margin-top: 15px;
        font-size: 1rem;
        height: 40px;
        @include media-max(500px) {
          font-size: 0.8em;
          margin-top: 10px;
        }
      }
    }
  }
}
