.product_categories_page {
  .banners {
    margin-bottom: 5%;
    p {
      display: flex;
      justify-content: center;

      img {
        width: 100%;
        height: 100%;
        margin-top: 3%;
      }
    }
  }
  width: 100%;
  display: grid;
  grid-template-columns: 270px 1fr;
  grid-gap: 0 20px;
  margin: 50px 0 0 0;
  @media (max-width: 1023px) {
    margin-top: 0;
  }
  @include media-max(1200px) {
    grid-template-columns: 100%;
    grid-gap: 0;
  }
  .products_grid_holder {
    @include media-min(1400px) {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  .left_side {
    .banners {
      p {
        display: flex;
        justify-content: center;

        img {
          width: 100%;
          height: 100%;
          margin-top: 5%;
        }
      }
    }

    @include media-max(1200px) {
      @include fixed-full;
      z-index: 9999;
      transform: translateX(-100vw);
      @include transition;
      & > div {
        width: 100%;
        height: 100vh;
        background: $color-white;
        overflow-y: scroll;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
        &::-webkit-scrollbar {
          width: 0;
        }
        a {
          img {
            width: 200px;
          }
        }
      }
      &.active {
        transform: translateX(0);
      }
    }
    a {
      width: 100%;
      @include flex-center;
      padding: 50px 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      img {
        width: 100%;
      }
    }
  }
  .categories_section {
    h1 {
      padding-bottom: 10px;
      margin-bottom: 20px;
      font-weight: 400;
      font-size: 1.5em;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      position: relative;
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 230px;
        height: 2px;
        background: $color-primary;
      }
    }
    .categories_wrap {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      grid-gap: 20px 0;
      margin-bottom: 50px;
      grid-auto-rows: 165px;
      @include media-max(1200px) {
        grid-template-columns: repeat(5, 1fr);
      }
      @include media-max(768px) {
        grid-template-columns: repeat(4, 1fr);
      }
      @include media-max(575px) {
        grid-template-columns: repeat(3, 1fr);
      }
      a {
        padding: 10px 5px;
        @include full-size;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        text-align: center;
        position: relative;
        color: $color-text;
        font-size: 14px;
        @include hover {
          box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        }
        &::after {
          content: '';
          position: absolute;
          top: 10%;
          bottom: 10%;
          right: 0;
          width: 1px;
          background: #ebebeb;
        }
        &:nth-child(6n) {
          &::after {
            display: none;
          }
        }
        @include media-max(1200px) {
          &:nth-child(5n) {
            &::after {
              display: none;
            }
          }
        }
        @include media-max(768px) {
          @include hover {
            box-shadow: none;
          }
          &:nth-child(6n) {
            &::after {
              display: block;
            }
          }
          &:nth-child(5n) {
            &::after {
              display: block;
            }
          }
          &:nth-child(4n) {
            &::after {
              display: none;
            }
          }
        }
        @include media-max(575px) {
          &:nth-child(6n) {
            &::after {
              display: block;
            }
          }
          &:nth-child(5n) {
            &::after {
              display: block;
            }
          }
          &:nth-child(4n) {
            &::after {
              display: block;
            }
          }
          &:nth-child(3n) {
            &::after {
              display: none;
            }
          }
        }
        img {
          width: 100px;
          margin-bottom: 10px;
          @include media-max(575px) {
            width: 80px;
          }
          &.placeholder {
            opacity: 0.3;
          }
        }
        div {
          height: 36px;
          display: flex;
          text-align: center;
          overflow: hidden;
        }
      }
    }
  }
}
